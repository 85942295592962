import styled from "styled-components";

import System from "~/environments/System";

export const MainCard = styled.div`
  border-radius: 10px;
  box-shadow: ${(props) => props.shadow};
  margin-top: 0;
  width: 100%;

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    border-radius: 0;
    padding-bottom: 10px;
  }
`;

export const CardHeader = styled.div`
  margin: 15px 0;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
`;

export const FlexCenterColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FlexBetweenRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
  padding: 0px 20px;

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    flex-direction: column;
    justify-content: center;
  }
`;
