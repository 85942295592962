import React, { useContext, memo } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//Libraries
import { InputNumber } from 'antd';

function AntInputNumber({ min, max, defaultValue, onChange, size, step }) {
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <InputNumber
      size={size || isMobile ? 'large' : 'default'}
      min={min}
      max={max}
      step={step}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
}

export default memo(AntInputNumber);
