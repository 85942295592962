import React, { useContext } from "react";
// Components

import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

import { ResponsiveContext } from '~/contexts/ResponsiveContext'

import Button from "~/components/button";
import Table from "~/components/table";
import {
  PrimaryAndSecondaryTextCell,
  TwoActionButtonCell,
} from "~/components/table/cells";

export default function TableActivity() {
  const { isMobile } = useContext(ResponsiveContext)

  const columns = [
    {
      title: "Programa",
      dataIndex: "name",
      key: "name",
      render: (name, record) => (
        <PrimaryAndSecondaryTextCell
          align={isMobile ? "center" : "flex-start"}
          primaryText={name}
          secondaryText={record.address}
        />
      ),
    },
    {
      title: "Ações",
      dataIndex: "situation",
      key: "action",
      align: "center",
      width: "25%",
      render: () => (
        <div>
          <Button type="dashed" icon={<CloseOutlined />} style={{ marginRight: 5 }}>
            Recusar
          </Button>
          <Button type="primary" icon={<CheckOutlined />}>
            Aceitar
          </Button>
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "Programa de exemplo",
      address: "Prefeitura Municipal de Boa Vista",
      date: "20/11/2019",
      value: "R$ 180,00",
      situation: "Recebido",
    },
    {
      key: "2",
      name: "Exemplo de Programa",
      address: "Prefeitura Municipal de Boa Vista",
      date: "11/10/2019",
      value: "R$ 88,00",
      situation: "Recebido",
    },
  ];
  return (
    <Table pagination={false} tableColumns={columns} dataReceived={data} />
  );
}
