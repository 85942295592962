import React, { memo } from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

function AntTextArea({ value, onChange, placeholder, autoSize }) {
  return (
    <TextArea
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      autoSize={autoSize}
    />
  );
}

export default memo(AntTextArea);
