export default {
  SME817D6E: {
    message: "The 'donation request' was successfully Updated",
  },
  SM9C6B247: {
    message: "The 'donation request product/service' was successfully Updated"
  },
  SMC9DFC93: {
    message: "The 'file' was successfully created"
  },
  SM3034963: {
    message: 'The role was successfully Updated',
},
};
