import React, { useContext, memo } from "react";

import { ThemeContext } from "~/contexts/ThemeContext";

import Link from "./style";

function ExternalLink({ to, target, position, color, children }) {
  const { theme } = useContext(ThemeContext);

  return (
    <Link
      position={position || "static"}
      color={theme.color.textOnSecondaryBackground || color}
      hovercolor={theme.color.primary}
      href={`https://${to}`}
      target={target || "_blank"}
    >
      {children}
    </Link>
  );
}

export default memo(ExternalLink);
