import styled from "styled-components";

import { Input } from "antd";

export const OptionsContainer = styled.div`
  display: ${(props) => props.display};
  min-height: 80px;
  max-height: ${(props) => `${props.height}px`};
  width: 100%;
  overflow: scroll;
  border-left: 1px solid ${(props) => props.borderColor};
  border-right: 1px solid ${(props) => props.borderColor};

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.scrollColor};
  }
`;

export const Item = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.borderColor};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 5px 15px;
  transition: all 0.5s;

  &:hover {
    border: 1px solid ${(props) => props.borderHoverColor};
  }
`;

export const Avatar = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-right: 15px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextInput = styled(Input)`
  width: ${(props) => props.width || "100%"};
  margin: ${(props) => props.margin || "0px"};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border-radius: 8px;
`;
