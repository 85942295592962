import React from "react";

import { Space } from "antd";

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

// Components
import Text from "~/components/typography/text";

import { LinksShareContainer } from "./style";

export default function ShareDonation({ donationId }) {
  return (
    <LinksShareContainer>
      <Text strong style={{ fontSize: 18, marginBottom: 10 }}>
        Ajude no compartilhamento!
      </Text>
      <Space>
        <FacebookShareButton
          url={`https://doacoes.me/doacoes/${donationId}`}
          quote="Social Me - Doações"
          hashtag="#socialMe"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <FacebookMessengerShareButton
          url={`https://doacoes.me/doacoes/${donationId}`}
        >
          <FacebookMessengerIcon size={32} round />
        </FacebookMessengerShareButton>
        <TwitterShareButton url={`https://doacoes.me/doacoes/${donationId}`}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <TelegramShareButton url={`https://doacoes.me/doacoes/${donationId}`}>
          <TelegramIcon size={32} round />
        </TelegramShareButton>
        <WhatsappShareButton url={`https://doacoes.me/doacoes/${donationId}`}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <EmailShareButton url={`https://doacoes.me/doacoes/${donationId}`}>
          <EmailIcon size={32} round />
        </EmailShareButton>
      </Space>
    </LinksShareContainer>
  );
}
