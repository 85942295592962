import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  flex-direction: column; 

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`

export const ListBox = styled.div`
  display: flex;
  padding: 5px;
  align-items: center; 

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`