import styled from 'styled-components';

import MaskedInputLibrary from 'antd-mask-input';
import { Input } from 'antd';

export const TextInput = styled(Input)`
  width: ${(props) => props.width || '100%'};
  margin: ${(props) => props.margin || '0px'};
  background-color: ${(props) => props.backgroundcolor};
  color: ${(props) => props.color};
  border-radius: 8px;
`;

export const MaskedInput = styled(MaskedInputLibrary)`
  width: ${(props) => props.width || '100%'};
  margin: ${(props) => props.margin || '0px'};
  background-color: ${(props) => props.backgroundcolor};
  color: ${(props) => props.color};
  border-radius: 8px;
`;

export const PasswordInput = styled(Input.Password)`
  width: ${(props) => props.width || '100%'};
  margin: ${(props) => props.margin || '0px'};
  background-color: ${(props) => props.backgroundcolor};
  color: ${(props) => props.color};
  border-radius: 8px;
`;
