import styled from "styled-components";

import System from "~/environments/System";

export const ProductServicesButtonContainer = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${`${System.TabletBreakpoint}px`}) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const ProductServicesContent = styled.div`
  width: 100%;
  margin: 10px 0 20px 0;
`;

export const TitleContent = styled.div`
  padding: 15px;
  border-bottom: 2px solid ${(props) => props.color};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0;
`;
