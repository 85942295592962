import React from 'react';
import Table from './components/Table';

export default function RequestsMade() {
  return (
    <div>
      <Table />
    </div>
  );
}
