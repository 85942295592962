
import React, { useContext } from "react";

import { CheckCircleOutlined, SmileOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ResponsiveContext } from '~/contexts/ResponsiveContext'


import Button from "~/components/button";
import Table from "~/components/table";
import {
  PrimaryTextCell,
  PrimaryAndSecondaryTextCell,
  BadgeCell,
} from "~/components/table/cells";

export default function TableActivity() {
  const { isMobile } = useContext(ResponsiveContext)

  const columns = [
    {
      title: "Programa",
      dataIndex: "name",
      key: "name",
      render: (name, record) => (
        <PrimaryAndSecondaryTextCell
          align={isMobile ? "center" : "flex-start"}
          primaryText={name}
          secondaryText={record.address}
        />
      ),
    },
   
    {
      title: "Situação",
      dataIndex: "situation",
      key: "situation",
      render: (situation) => (
        <BadgeCell
          icon={
            situation === "Programa Ativo" ? (
              <CheckCircleOutlined />
            ) : (
              <PlusCircleOutlined />
            )
          }
          color={situation === "Programa Ativo" ? "success" : "processing"}
          text={situation}
        />
      ),
    },
    {
      title: "Valor",
      dataIndex: "money",
      key: "money",
      render: (value) => (
        <PrimaryTextCell
          text={value} 
        />
      ),
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      render: () => (
        <Button type="primary" icon={<SmileOutlined />}>
          Reivindicar
        </Button>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "Mensalidade do Programa",
      address: "Ajuda Financeira",
      value: "Bolsa Família",
      money: "R$ 205,00",
      situation: "Programa Ativo",
    },
    {
      key: "2",
      name: "Leite",
      address: "Ajuda Alimentícia",
      value: "Família que Acolhe",
      money: "R$ 110,00",
      situation: "Programa Ativo",
    },
    {
      key: "3",
      name: "Enxoval Completo",
      address: "Ajuda com bebês",
      value: "SIS Pré-natal",
      money: "R$ 167,00",
      situation: "Programa Ativo",
    },
  ];
  return (
    <Table pagination={false} tableColumns={columns} dataReceived={data} />
  );
}
