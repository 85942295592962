import React, { useState } from "react";

// Components
import SocialMeAPI from "~/utils/SocialMeAPI";

import Button from "~/components/button";
import { UploadSingle } from "~/components/uploadInput";

import { message } from "antd";

import {
  SubImagesContent,
  UploadContainer,
  ButtonsContainer,
  Image,
} from "./style";

export default function DonationAvatar({
  donation,
  setDonation,
  GoBack,
  NextStep,
  isDebug,
}) {
  const [file, setFile] = useState("");

  async function AddImageInDonation() {
    const data = new FormData();

    data.append("file", file);

    let params = {
      operation: ["Donation", "File"],
      urlParams: {
        donationId: donation.id,
      },
      data,
    };

    if (isDebug) console.log("📦 Params: ", params);

    const response = await SocialMeAPI(params);

    if (isDebug) console.log("🚀 API Response, Donation File: ", response);

    if (file === null) {
      message.error("Selecione uma image clicando no Retângulo.");
    } else {
      params = {
        operation: ["Donation", "Edit"],
        urlParams: {
          donationId: donation.id,
        },
        data: {
          avatar: response.body.newFile,
        },
      };

      if (isDebug) console.log("📦 Params: ", params);

      const anotherResponse = await SocialMeAPI(params);

      if (isDebug)
        console.log("🚀 API Response, Donation Edit: ", anotherResponse);

      if (anotherResponse.success) {
        if (isDebug) console.log("Image added to donation");

        message.success("Uma nova imagem foi adicionada em sua doação.");

        setDonation({
          ...donation,
          images: anotherResponse.body.donationRequest.images,
        });
        setFile("");
      } else if (isDebug) {
        console.log("Image was not added to donation");
      }
    }
  }

  function goNextStep() {
    if (donation.images !== null) {
      NextStep();
    } else {
      message.error("Adicione alguma imagem para sua doação");
    }
  }

  return (
    <>
      <UploadContainer>
        <UploadSingle file={file} setFile={setFile} name="upload" />
        <Button
          style={{ marginTop: "20px" }}
          disabled={file === ""}
          type="primary"
          onClick={AddImageInDonation}
        >
          Anexar foto
        </Button>
      </UploadContainer>

      <SubImagesContent>
        {donation.images !== null ? (
          donation.images.map((image, index) => (
            <Image key={image} shape="square" src={image} size={100} />
          ))
        ) : (
          <div />
        )}
      </SubImagesContent>

      <ButtonsContainer>
        <Button onClick={GoBack}>Voltar</Button>
        <Button
          type="primary"
          disabled={donation.images === null}
          onClick={goNextStep}
        >
          Proximo Passo
        </Button>
      </ButtonsContainer>
    </>
  );
}
