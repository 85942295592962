import React from "react";
// Components
import { Card, Typography, Divider } from "antd";
import Table from "./components/Table";

import { Div, CenterSpaceBewtween } from "./style";

const { Title } = Typography;

export default function Programs() {
  // Variables
  return (
    <Div>
      <Card>
        <CenterSpaceBewtween>
          <Title level={4}>Meus Programas</Title>
        </CenterSpaceBewtween>
        <Divider />
        <Table />
      </Card>
    </Div>
  );
}
