import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DebugContextProvider from "~/contexts/DebugContext";
import AxiosContextProvider from "~/contexts/AxiosContext";
import BrowserContextProvider from "~/contexts/BrowserContext";
import LanguageContextProvider from "~/contexts/LanguageContext";
import ResponsiveContextProvider from "~/contexts/ResponsiveContext";
import ThemeContextProvider from "~/contexts/ThemeContext";
import AuthContextProvider from "~/contexts/AuthContext";
import InterfaceContextProvider from "~/contexts/InterfaceContext";
import ContractsContextProvider from "~/contexts/ContractsContext";
import SidebarContextProvider from "~/contexts/SidebarContext";
import "~/assets/css/antDesignCustom.css";
// Pages
import Page from "~/pages/BeneficiaryCheck";
// import Page from "~/pages/AuthenticationCheck";

export default function App() {
  return (
    <Router>
      <DebugContextProvider>
        <AxiosContextProvider>
          <BrowserContextProvider>
            <LanguageContextProvider>
              <ResponsiveContextProvider>
                <ThemeContextProvider>
                  <AuthContextProvider>
                    <InterfaceContextProvider>
                      <ContractsContextProvider>
                        <SidebarContextProvider>
                          <Switch>
                            <Route path="/" component={Page} />
                          </Switch>
                        </SidebarContextProvider>
                      </ContractsContextProvider>
                    </InterfaceContextProvider>
                  </AuthContextProvider>
                </ThemeContextProvider>
              </ResponsiveContextProvider>
            </LanguageContextProvider>
          </BrowserContextProvider>
        </AxiosContextProvider>
      </DebugContextProvider>
    </Router>
  );
}
