import styled from "styled-components";

import System from "~/environments/System";

export const ContainerList = styled.div`
  width: 100%;
  height: 200px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 15px;
  padding: 0 10px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #340348;
    border-radius: 20px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  padding: 0;
  list-style: none;
  box-sizing: content-box;

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    margin-bottom: 15px;
  }
`;

export const ListItem = styled.li`
  display: flex;
  padding: 10px 5px;
  align-items: ${(props) => props.position || "center"};
  justify-content: ${(props) => props.position || "center"};
  width: ${(props) => props.width || "50%"};

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    padding: 10px 0;
  }
`;

export const PrimarySecondaryText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;
