import React, { useState, useEffect, useContext } from "react";

import { Card, Typography } from "antd";

import { ThemeContext } from "~/contexts/ThemeContext";

import Button from "~/components/button";
import QRCodeReader from "~/components/qrcode/reader";

import ActivitiesTable from "./components/ActivitiesTable";
import Frequencie from "./components/Frequencie";

import SocialMeAPI from "~/utils/SocialMeAPI";
import Divider from "~/components/divider";

import { FlexContent } from "./style";

const { Title } = Typography;

export default function MyActivities() {
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [activitys, setActivitys] = useState([]);

  const { theme } = useContext(ThemeContext);

  async function LoadActivitysFromAPI() {
    const params = {
      operation: ["Beneficiery", "Get"],
      data: {
        pagination: {
          sort: {
            by: "registered_at",
            order: "desc",
          },
        },
      },
    };

    const response = await SocialMeAPI(params);

    setActivitys(response.body.frequencies.elements);
  }

  useEffect(() => {
    LoadActivitysFromAPI();
  }, []);

  function OpenDrawer() {
    setDrawerVisibility(true);
  }

  function CloseDrawer() {
    setDrawerVisibility(false);
  }

  return (
    <div>
      <Card>
        <FlexContent>
          <Title level={4}>Minhas atividades</Title>

          <Button onClick={OpenDrawer} type="primary">
            Registrar Frequência
          </Button>

          <QRCodeReader
            Component={{
              mount: Frequencie,
              actions: {
                LoadActivitysFromAPI,
              },
            }}
            CloseDrawer={CloseDrawer}
            visibility={drawerVisibility}
            qrCodeType="ACTIVITY_PLACE"
            qrCodeDescription="Coloque um QRCode dentro desta tela com bordas brancas para ser lido"
            qrCodeTitle="Adicionar a frequência na atividade"
          />
        </FlexContent>
        <Divider borderColor={theme.color.white} />
        <ActivitiesTable activitys={activitys} />
      </Card>
    </div>
  );
}
