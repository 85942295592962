import React, { useContext } from "react";
// Contexts^
import { AiOutlineTransaction, AiFillHeart } from "react-icons/ai";
import { Route, Switch, Redirect } from "react-router-dom";
import { Layout } from "antd";
import { ThemeContext } from "~/contexts/ThemeContext";
// import { LanguageContext } from '~/contexts/LanguageContext';
// Components
import Sidebar from "~/components/sidebar";
import MainNavbar from "~/components/navbar/Main";

// import Home from "./pages/Home";
import Activities from "./pages/Activities";
import Moves from "./pages/Moves";
import Donations from "./pages/Donations";
import Programs from "./pages/Programs";
import Opportunities from "./pages/Opportunities";

const { Content } = Layout;

export default function Profile() {
  const { theme } = useContext(ThemeContext);
  // const { translate } = useContext(LanguageContext);

  const sidebarItems = [
    {
      index: 0,
      title: "First",
    },
    // {
    //   index: 1,
    //   title: "Início",
    //   url: "/inicio",
    // },
    {
      index: 1,
      title: "Atividades",
      url: "/atividades",
    },
    {
      index: 2,
      title: "Doações",
      url: "/doacoes",
      icon: AiFillHeart,
    },
    {
      index: 3,
      title: "Movimentações",
      url: "/movimentacoes",
      icon: AiOutlineTransaction,
    },
    {
      index: 4,
      title: "Programas",
      url: "/programas",
    },
    {
      index: 5,
      title: "Oportunidades",
      url: "/oportunidades",
    },
    {
      index: 6,
      title: "Last",
    },
  ];

  return (
    <div>
      <Layout
        style={{
          backgroundColor: theme.color.backgroundPrimary,
          height: "100vh",
        }}
      >
        <Sidebar items={sidebarItems} />
        <Layout
          style={{
            padding: 10,
            backgroundColor: theme.color.backgroundSecondary,
          }}
        >
          <MainNavbar />
          <Content
            style={{
              overflow: "auto",
            }}
          >
            <Switch>
              <Route exact path="/">
                <Redirect to="/doacoes" />
              </Route>
              {/* <Route exact path={`/inicio`} component={Home} /> */}
              <Route path="/atividades" component={Activities} />
              <Route path="/movimentacoes" component={Moves} />
              <Route path="/doacoes" component={Donations} />
              <Route path="/programas" component={Programs} />
              <Route path="/oportunidades" component={Opportunities} />
              <Route exact path="*">
                <Redirect to="/doacoes" />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
