import React from "react";

import { CheckCircleOutlined } from '@ant-design/icons'

import Text from "~/components/typography/text";

import { Container } from "./style";

export default function AgreedTermsModal({ theme }) {


  return (
    <Container>
      <CheckCircleOutlined style={{ color: theme.color.success, fontSize: 48, marginBottom: 15 }} />
      <Text color={theme.color.textOnSecondaryBackground} style={{ fontSize: 16}}>O seu pedido de doação foi concluido e será mandado para análise, se ele for publicado você pode acompanha-lo nas suas doações.</Text>
    </Container>
  );
}
