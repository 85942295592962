import React, { useState, createContext, useEffect } from "react";

import System from "~/environments/System";

export const ResponsiveContext = createContext();

const ResponsiveContextProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  const updateWidth = () => {
    if (window.innerWidth <= System.ResponsiveBreakpoint) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const updateWidthInTablet = () => {
    if (window.innerWidth <= System.TabletBreakpoint) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= System.ResponsiveBreakpoint) {
      setIsMobile(true);
    }

    if (window.innerWidth <= System.TabletBreakpoint) {
      setIsTablet(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
      window.removeEventListener("resize", updateWidthInTablet);
    };
  });

  return (
    <ResponsiveContext.Provider value={{ isMobile, isTablet }}>
      {children}
    </ResponsiveContext.Provider>
  );
};

export default ResponsiveContextProvider;
