export default {
  SME9664D8: {
    message: "The qr-code was successfully Read",
  },
  SMCB44BB1: {
    message: "The 'donation request' was successfully Listed",
  },
  SM8EEEC31: {
    message: "The 'donation request' was successfully Read",
  },
  SMC7BCEEA: {
    message: "The 'product/service model' was successfully Listed",
  },
  SM8117F92: {
    message: "The 'measurement unity' was successfully Listed",
  },
  SMB2D88E7: {
    message: "The 'detailed account balance' was successfully Read",
  },
};
