import React, { memo } from "react";
// Standalone Components
import { MainCard } from "./style";

function AntCard({ children, bordered, bodyStyle, style }) {
  return (
    <MainCard bordered={bordered} bodyStyle={bodyStyle} style={style}>
      {children}
    </MainCard>
  );
}

export default memo(AntCard);
