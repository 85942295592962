import React from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import Text from '~/components/typography/text';

export default function Description({ text, color, icon, textAlign }) {
  return (
    <div
      style={{
        padding: '10px 5px',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      {icon ? (
        <CloseCircleFilled
          size={50}
          style={{ color: color, margin: '4px 8px' }}
        />
      ) : (
        <div />
      )}
      <Text textAlign="center" color={color} strong={true}>
        {text}
      </Text>
    </div>
  );
}
