import React from "react";
import Badge from "~/components/badge";
import Text from "~/components/typography/text";

import {
  FilterContainer,
  FilterCard,
  CloseIcon,
  IssuesIcon,
  CheckIcon,
  SyncIcon,
} from "./style";

export default function Filters({
  theme,
  donationStatus,
  donationCount,
  setDonationStatus,
  setCurrentPage,
  setLimit,
}) {
  function isActive(text) {
    return donationStatus.type === text;
  }

  return (
    <FilterContainer>
      <Badge count={donationCount.incomplete} style={{ marginRight: "10px" }}>
        <FilterCard
          onClick={() => {
            setCurrentPage(1);
            setLimit(10);
            setDonationStatus({
              type: "complete",
              setCurrentPage: 1,
              setLimit: 10,
              isComplete: false,
              isResolved: false,
              isPublished: false,
            });
          }}
          active={isActive("complete")}
          borderColor={theme.color.primary}
          backgroundColor={
            isActive("complete")
              ? theme.color.primary
              : theme.color.textOnPrimaryBackground
          }
          textColor={
            isActive("complete")
              ? theme.color.textOnPrimaryBackground
              : theme.color.primary
          }
        >
          <IssuesIcon style={{ fontSize: 20 }} />
          <Text style={{ textAlign: "left" }}>Doações incompletas</Text>
        </FilterCard>
      </Badge>

      <Badge count={donationCount.complete} style={{ marginRight: "10px" }}>
        <FilterCard
          onClick={() => {
            setCurrentPage(1);
            setLimit(10);
            setDonationStatus({
              type: "processing",
              setCurrentPage: 1,
              setLimit: 10,
              isComplete: true,
              isResolved: false,
              isPublished: false,
            });
          }}
          active={isActive("processing")}
          borderColor={theme.color.primary}
          backgroundColor={
            isActive("processing")
              ? theme.color.primary
              : theme.color.textOnPrimaryBackground
          }
          textColor={
            isActive("processing")
              ? theme.color.textOnPrimaryBackground
              : theme.color.primary
          }
        >
          <SyncIcon style={{ fontSize: 20 }} />
          <Text>Doações em análise</Text>
        </FilterCard>
      </Badge>

      <Badge count={donationCount.published} style={{ marginRight: "10px" }}>
        <FilterCard
          onClick={() => {
            setCurrentPage(1);
            setLimit(10);
            setDonationStatus({
              type: "publish",
              setCurrentPage: 1,
              setLimit: 10,
              isComplete: true,
              isResolved: true,
              isPublished: true,
            });
          }}
          active={isActive("publish")}
          borderColor={theme.color.primary}
          backgroundColor={
            isActive("publish")
              ? theme.color.primary
              : theme.color.textOnPrimaryBackground
          }
          textColor={
            isActive("publish")
              ? theme.color.textOnPrimaryBackground
              : theme.color.primary
          }
        >
          <CheckIcon style={{ fontSize: 20 }} />
          <Text>Doações publicadas</Text>
        </FilterCard>
      </Badge>

      <Badge count={donationCount.rejected} style={{ marginRight: "10px" }}>
        <FilterCard
          onClick={() => {
            setCurrentPage(1);
            setLimit(10);
            setDonationStatus({
              type: "reject",
              setCurrentPage: 1,
              setLimit: 10,
              isComplete: true,
              isResolved: true,
              isPublished: false,
            });
          }}
          active={isActive("reject")}
          borderColor={theme.color.primary}
          backgroundColor={
            isActive("reject")
              ? theme.color.primary
              : theme.color.textOnPrimaryBackground
          }
          textColor={
            isActive("reject")
              ? theme.color.textOnPrimaryBackground
              : theme.color.primary
          }
        >
          <CloseIcon style={{ fontSize: 20 }} />
          <Text>Doações Rejeitadas</Text>
        </FilterCard>
      </Badge>
    </FilterContainer>
  );
}
