import React, { useState } from "react";
// Components
import AgreeWithProductsAndServices from "./components/AgreeWithProductsAndServices";
import DonationAvatar from "./components/DonationAvatar";
import Terms from "./components/Terms";

export default function SocialComplement({
  donation,
  setDonation,
  GoBackOneStep,
  isDebug,
}) {
  const [step, setStep] = useState(0);

  function RenderStep() {
    switch (step) {
      case 0:
        return (
          <AgreeWithProductsAndServices
            donation={donation}
            GoBackOneStep={GoBackOneStep}
            NextStep={() => setStep(1)}
            isDebug={isDebug}
          />
        );
      case 1:
        return (
          <DonationAvatar
            donation={donation}
            setDonation={setDonation}
            GoBack={() => setStep(0)}
            currentStep={() => setStep(1)}
            NextStep={() => setStep(2)}
            isDebug={isDebug}
          />
        );
      case 2:
        return (
          <Terms
            donation={donation}
            donationId={donation.id}
            GoBack={() => setStep(1)}
            isDebug={isDebug}
          />
        );
      default:
    }
  }

  return RenderStep();
}
