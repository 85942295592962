import styled from 'styled-components'

export const Margin = styled.div`
  margin: 20px 0;
`

export const ButtonContainer = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
