import React, { useContext } from "react";

import { UserDeleteOutlined  } from "@ant-design/icons";

import { ResponsiveContext } from '~/contexts/ResponsiveContext'

import Button from "~/components/button";
import Table from "~/components/table";
import {
  PrimaryAndSecondaryTextCell,
} from "~/components/table/cells";

export default function TableActivity() {

  const { isMobile } = useContext(ResponsiveContext);

  const columns = [
    {
      title: "Programa",
      dataIndex: "name",
      key: "name",
      render: (name, record) => (
        <PrimaryAndSecondaryTextCell
          align={isMobile ? "center" : "flex-start"}
          primaryText={name}
          secondaryText={record.address}
        />
      ),
    },
    {
      title: "Situação",
      dataIndex: "situation",
      key: "situation",
      align: "center",
      width: "15%",
      render: () => (
        <Button type="dashed" icon={<UserDeleteOutlined />}>
          Cancelar solicitação
        </Button>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "Programa do Governo",
      address: "Prefeitura Municipal de Boa Vista",
      date: "20/11/2019",
      value: "R$ 180,00",
      situation: "Recebido",
    },
    {
      key: "2",
      name: "Agora é um programa da Prefeitura",
      address: "Prefeitura Municipal de Boa Vista",
      date: "11/10/2019",
      value: "R$ 88,00",
      situation: "Recebido",
    },
  ];
  return (
    <Table pagination={false} tableColumns={columns} dataReceived={data} />
  );
}
