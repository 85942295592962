import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
  height: 120px;
  border: 2px solid ${(props) => props.backgroundColor};
  background-color: color;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  transition: all 0.2s;

  & svg {
      fill: ${props => props.color};
    }

  & strong {
    color: ${props => props.color};
  }

  &:hover {
    border: 2px solid ${(props) => props.borderColor};
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Div = styled.div``;

export const FlexCenter = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Padding = styled.div`
  padding: 5px;
`;
