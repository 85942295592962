import React, { useContext } from "react";

import Text from "~/components/typography/text";
import { ResponsiveContext } from "~/contexts/ResponsiveContext";

import {
  WalletFormatConversion,
  CapitalizeString,
} from "~/utils/Transformations";

import { Container, ListItem, Avatar, TextContent } from "./style";

export default function ProductServicesItem({
  productService,

  theme,
}) {
  const {
    ps_kind,
    description,
    ps_avatar,
    name,
    value,
    quantity,
  } = productService;

  const { isMobile, isTablet } = useContext(ResponsiveContext);

  return (
    <Container color={theme.color.textOnPrimaryBackground}>
      <ListItem width={isTablet ? "100%" : "50%"}>
        <Avatar src={ps_avatar} size="50px" />
        <TextContent>
          <Text strong textAlign="left">
            {CapitalizeString(
              `${ps_kind === "PRODUCT" ? "Produto:" : "Serviço:"} ${name}`
            )}
          </Text>
          <Text textAlign="left">{CapitalizeString(description)}</Text>
        </TextContent>
      </ListItem>

      <ListItem
        width={isTablet ? "50%" : isMobile ? "100%" : "25%"}
        column
        align="center"
      >
        <Text>{`Unidade: ${WalletFormatConversion(value)}`}</Text>
        <Text>{`Quantidade: ${quantity}`}</Text>
      </ListItem>

      <ListItem width={isTablet ? "50%" : isMobile ? "100%" : "25%"}>
        <Text strong>{`Total: ${WalletFormatConversion(
          value * quantity
        )}`}</Text>
      </ListItem>
    </Container>
  );
}
