import React from "react";
// Libraries
import { Route, Switch, useRouteMatch } from "react-router-dom";
// Pages
import MyRequests from "./pages/MyRequests";
import RequestDonation from "./pages/RequestDonation";

export default function Relationships() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={MyRequests} />
      <Route
        exact
        path={`${path}/solicitar-doacao`}
        component={RequestDonation}
      />
      <Route
        path={`${path}/solicitar-doacao/:donationId`}
        component={RequestDonation}
      />
    </Switch>
  );
}
