import styled from "styled-components";

import System from "~/environments/System";

import { DeleteOutlined } from "@ant-design/icons";

export const Container = styled.div`
  background-color: ${(props) => props.color};
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 1px solid ${(props) => props.borderColor};
  padding: 10px;

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    min-width: 100%;
    padding: 5px;
  }
`;

export const ListItem = styled.div`
  width: ${(props) => props.width || "50%"};
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  align-items: ${(props) => props.align || "flex-start"};
  justify-content: ${(props) => props.align || "flex-start"};
  padding: 10px;
`;

export const Avatar = styled.img`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  margin: 0 15px 0 5px;
`;

export const DeleteIcon = styled(DeleteOutlined)`
  font-size: 18px;
  color: red;
  margin-left: 15px;
`;

export const TextContent = styled.div``;
