import React, { memo, useMemo, useContext } from "react";

import { ThemeContext } from "~/contexts/ThemeContext";

import imageCompression from "browser-image-compression";

import { Label, Input } from "./style";

const UploadInput = ({ file, name, setFile, label, width, height }) => {
  const { theme } = useContext(ThemeContext);

  const preview = useMemo(() => {
    return file !== "" ? URL.createObjectURL(file) : file;
  }, [file]);

  const onChangeFile = async (files) => {
    const file = files[0];

    await imageCompression.getExifOrientation(file);

    setFile(file);

    const options = {
      maxWidthOrHeight: 600,
      maxSizeMB: 1.5,
      useWebWorker: true,
      initialQuality: 0.7,
    };

    const output = await imageCompression(file, options);

    output.lastModifiedDate = new Date();

    // Conver the blob to file
    const outputFile = new File([output], file.name, {
      type: file.type,
      lastModified: Date.now(),
    });

    setFile(outputFile);
  };

  return (
    <>
      <Label
        width={width || 300}
        height={height || 250}
        hoverColor={theme.color.primary}
        border={`${
          preview === "" ? `3px solid ${theme.color.borderColor}` : ""
        }`}
        preview={preview}
        htmlFor={name}
      >
        {`${
          preview !== "" ? "" : label || "Clique aqui para adicionar uma imagem"
        }`}
      </Label>
      <Input
        type="file"
        value=""
        onChange={(event) => onChangeFile(event.target.files)}
        name={name}
        id={name}
        required
      />
    </>
  );
};

export default memo(UploadInput);
