import React, { useContext } from "react";
// Components
import { UserAddOutlined } from "@ant-design/icons";

import { ResponsiveContext } from '~/contexts/ResponsiveContext'

import Button from "~/components/button";
import Table from "~/components/table";
import { PrimaryAndSecondaryTextCell } from "~/components/table/cells";

export default function TableActivity() {
  const { isMobile } = useContext(ResponsiveContext)

  const columns = [
    {
      title: "Programa",
      dataIndex: "name",
      key: "name",
      render: (name, record) => (
        <PrimaryAndSecondaryTextCell
          align={isMobile ? "center" : "flex-start"}
          primaryText={name}
          secondaryText={record.address}
        />
      ),
    },
    {
      title: "Ações",
      dataIndex: "situation",
      key: "action",
      align: "center",
      width: "20%",
      render: () => (
        <Button type="primary" icon={<UserAddOutlined />}>
          Solicitar entrada
        </Button>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "Carteira da Criança",
      address: "Prefeitura Municipal de Boa Vista",
      date: "20/11/2019",
      value: "R$ 180,00",
      situation: "Recebido",
    },
    {
      key: "2",
      name: "Inspira Boa Vista",
      address: "Prefeitura Municipal de Boa Vista",
      date: "11/10/2019",
      value: "R$ 88,00",
      situation: "Recebido",
    },
    {
      key: "3",
      name: "COOPCAS",
      address: "Prefeitura Municipal de Boa Vista",
      date: "17/09/2019",
      value: "R$ 100,00",
      situation: "Disponível",
    },
  ];
  return (
    <Table pagination={false} tableColumns={columns} dataReceived={data} />
  );
}
