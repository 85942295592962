import React from 'react';
import QrReader from 'react-qr-reader';
import SocialMeAPI from '~/utils/SocialMeAPI';
import image from '~/assets/images/backgrounds/bg1.jpg';

import './style.css';

export default function QRCodeReader({ setQRCodeInfo, setQRCodeError }) {
  const GetQRCodeScanResult = async (scanResult) => {
    if (scanResult) {
      const params = {
        operation: ['QRCode', 'Decode'],
        urlParams: {
          qrcodeId: scanResult,
        },
      };
      const response = await SocialMeAPI(params);

      if (response.success) {
        if (response.body.QRCode.kind === 'ACTIVITY_PLACE') {
          setQRCodeInfo(response.body.QRCode);
        } else {
          setQRCodeError('Este QRCode não está relacionado a Frequencia.');
        }
      } else if (!response.success) {
        setQRCodeError('QRCode deu Error');
      }
    }
  };

  async function GetQRCodeSancError(err) {
    console.error(`Error: ${err}`);
  }

  return (
    <div id="qr-code-reader">
      <QrReader
        delay={600}
        onError={GetQRCodeSancError}
        onScan={GetQRCodeScanResult}
        openImageDialog={image}
      />
    </div>
  );
}
