import React, { useState } from "react";
// Components
import { Link, useRouteMatch } from "react-router-dom";
import { EditFilled, EyeFilled } from "@ant-design/icons";

import { Modal } from "antd";

import SocialMeAPI from "~/utils/SocialMeAPI";

import NegationWarningModal from "./components/NegationWarningModal";
import DonationDetailsModal from "./components/DonationDetailsModal";

import Table from "~/components/table";
import { PrimaryTextCell, ImageWithTextCell } from "~/components/table/cells";
import Button from "~/components/button";

import Debug from "~/environments/Debug";

import { ButtonContainer } from "./style";

export default function DonationTable({ donations, theme }) {
  const { path } = useRouteMatch();

  const [donation, setDonation] = useState(null);

  const [detailsModalVisibility, setDetailsModalVisibility] = useState(false);
  const [warningModalVisibility, setWarningModalVisibility] = useState(false);

  const [negationDetails, setNegationDetails] = useState({});

  const isDebug = Debug.Beneficiary.Donation;

  async function OpenDetailsModal(id) {
    const params = {
      operation: ["Donation", "Show"],
      urlParams: {
        donationId: id,
      },
    };

    if (isDebug) console.log("📦 Params: ", params);

    const response = await SocialMeAPI(params);

    if (isDebug) console.log("🚀 API Response, Donation Show: ", response);

    if (response.success) {
      if (isDebug) console.log("Donation found!");
      setDonation(response.body.donationRequest);
      setDetailsModalVisibility(true);
    } else if (isDebug) {
      console.log("No donation was found!");
    }
  }

  async function OpenNegationWarningModal(id, description) {
    if (isDebug) console.log("Negation Request Modal description", description);

    setNegationDetails({
      id,
      description,
    });

    setWarningModalVisibility(true);
  }

  const columns = [
    {
      title: "Doação",
      dataIndex: "is_complete",
      key: "is_complete",
      align: "left",
      width: "40%",
      render: (is_complete, record) => {
        return !is_complete ? (
          <PrimaryTextCell text={record.name || "Nome da minha doação"} />
        ) : (
          <ImageWithTextCell
            src={record.avatar}
            text={record.name || "Nome da minha doação"}
          />
        );
      },
    },
    {
      title: "Suas Ações",
      dataIndex: "id",
      key: "id",
      align: "right",
      width: "20%",
      render: (
        id,
        { is_published, is_resolved, is_complete, resolved_by_comment }
      ) =>
        is_published ? (
          <ButtonContainer>
            <Button onClick={() => OpenDetailsModal(id)} icon={<EyeFilled />}>
              Detalhes
            </Button>
          </ButtonContainer>
        ) : is_resolved ? (
          <ButtonContainer>
            <Button
              onClick={() => OpenNegationWarningModal(id, resolved_by_comment)}
              icon={<EditFilled />}
            >
              Necessário edição
            </Button>
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <Link to={`${path}/solicitar-doacao/${id}`}>
              <Button icon={<EditFilled />} disabled={!!is_complete}>
                Editar
              </Button>
            </Link>
          </ButtonContainer>
        ),
    },
  ];

  return (
    <>
      <Modal
        style={{ padding: 10 }}
        title="Detalhes da sua doação"
        visible={detailsModalVisibility}
        onCancel={() => setDetailsModalVisibility(false)}
        footer={[
          <Button
            onClick={() => setDetailsModalVisibility(false)}
            type="primary"
          >
            Esta bem
          </Button>,
        ]}
      >
        <DonationDetailsModal theme={theme} donation={donation} />
      </Modal>
      <Modal
        style={{ padding: 10 }}
        title="Motivo do seu pedido negado."
        visible={warningModalVisibility}
        onCancel={() => setWarningModalVisibility(false)}
        footer={[
          <Link to={`${path}/solicitar-doacao/${negationDetails.id}`}>
            <Button type="primary">Certo, vou editar</Button>
          </Link>,
        ]}
      >
        <NegationWarningModal
          theme={theme}
          description={negationDetails.description}
        />
      </Modal>
      <Table tableColumns={columns} dataReceived={donations} />
    </>
  );
}
