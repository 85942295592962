import React, { useContext, memo } from "react";
// Contexts
import { ResponsiveContext } from "~/contexts/ResponsiveContext";
import { ThemeContext } from "~/contexts/ThemeContext";
// Libraries
import IconHelper from "~/components/iconHelper";
// Standalone Components
import { TextInput, PasswordInput, MaskedInput } from "./style";

function AntInput({
  mask,
  disabled,
  placeholder,
  size,
  type,
  prefix,
  width,
  margin,
  value,
  onChange,
  textHelper,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);
  return mask ? (
    <MaskedInput
      disabled={!!disabled}
      mask={mask}
      value={value}
      onChange={onChange}
      size={isMobile ? "large" : size ? size : "default"}
      placeholder={placeholder}
      type={type ? type : "text"}
      prefix={prefix ? prefix : false}
      margin={margin}
      width={width}
      color={theme.color.textOnSecondaryBackground}
      backgroundcolor={theme.color.backgroundSecondary}
    />
  ) : type === "password" ? (
    <PasswordInput
      disabled={!!disabled}
      size={isMobile ? "large" : size ? size : "default"}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      type={"password"}
      prefix={prefix ? prefix : false}
      margin={margin}
      width={width}
      color={theme.color.textOnSecondaryBackground}
      backgroundcolor={theme.color.backgroundSecondary}
      autoComplete="password"
    />
  ) : (
    <TextInput
      disabled={!!disabled}
      size={isMobile ? "large" : size ? size : "default"}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      type={type ? type : "text"}
      prefix={prefix ? prefix : false}
      suffix={textHelper ? <IconHelper text={textHelper} /> : <div />}
      margin={margin}
      width={width}
      color={theme.color.textOnSecondaryBackground}
      backgroundcolor={theme.color.textOnPrimaryBackground}
    />
  );
}

export default memo(AntInput);
