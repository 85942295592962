import React, { useContext } from 'react';
import { message , Avatar } from 'antd';

import SocialMeAPI from '~/utils/SocialMeAPI';
import { ThemeContext } from '~/contexts/ThemeContext';

import { CapitalizeString } from '~/utils/Transformations';

import Button from '~/components/button';
import Text from '~/components/typography/text';



export default function Frequencie({
  QRCodeInfo,
  setQRCodeInfo,
  CloseDrawer,
  realoadActivitysFromAPI,
}) {
  const { theme } = useContext(ThemeContext);

  async function createFrequencieInActivity() {
    const params = {
      operation: ['Beneficiery', 'Add'],
      urlParams: {
        activityId: QRCodeInfo.activity_id,
      },
      data: {
        place: QRCodeInfo.place_id,
      },
    };

    const response = await SocialMeAPI(params);

    if (response.success) {
      message.success('A Frequencia nesta atividade foi feita com sucesso!');

      realoadActivitysFromAPI();
    }

    setQRCodeInfo([]);

    CloseDrawer();
  }

  async function cancel() {
    setQRCodeInfo([]);
  }

  return (
    <div
      style={{
        padding: 5,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Avatar
          style={{
            border: `3px solid ${theme.color.textOnPrimaryBackground}`,
            margin: '15px 0',
          }}
          size={100}
          src={QRCodeInfo.activity_avatar}
        />
        <Text
          strong
          color={theme.color.textOnPrimaryBackground}
          textAlign="center"
        >
          {CapitalizeString(QRCodeInfo.activity_name)}
        </Text>
        <Text
          style={{
            fontSize: '10px',
          }}
          color={theme.color.textOnPrimaryBackground}
          textAlign="center"
        >
          {CapitalizeString(QRCodeInfo.activity_type_name)}
        </Text>
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          margin: '20px 0',
        }}
      >
        <Avatar
          style={{
            border: `2px solid ${theme.color.textOnPrimaryBackground}`,
            margin: '0 15px',
          }}
          size={50}
          src={QRCodeInfo.person_avatar}
        />
        <Text color={theme.color.textOnPrimaryBackground} textAlign="justify">
          {CapitalizeString(QRCodeInfo.person_name)}
        </Text>
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: '20px',
        }}
      >
        <Avatar
          style={{
            border: `2px solid ${theme.color.textOnPrimaryBackground}`,
            margin: '0 15px',
          }}
          size={50}
          src={QRCodeInfo.place_avatar}
        />
        <Text color={theme.color.textOnPrimaryBackground} textAlign="center">
          {CapitalizeString(QRCodeInfo.place_name)}
        </Text>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Button
          onClick={createFrequencieInActivity}
          size="large"
          type="primary"
        >
          Adicionar Atividade
        </Button>
        <Button onClick={cancel} type="link">
          <Text color={theme.color.textOnPrimaryBackground}>Cancelar</Text>
        </Button>
      </div>
    </div>
  );
}
