import React from "react";
// Components

import { EyeOutlined , CheckCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

import Button from "~/components/button";

import Table from "~/components/table";
import {
  PrimaryTextCell,
  PrimaryAndSecondaryTextCell,
  BadgeCell,
} from "~/components/table/cells";



export default function TableActivity() {
  const columns = [
    {
      title: "Programa",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (name) => <PrimaryTextCell textAlign="center" text={name} />,
    },
    {
      title: "Situação",
      dataIndex: "situation",
      key: "situation",
      align: "center",
      render: (situation) => (
        <BadgeCell
          margin="auto"
          icon={
            situation === "Programa Ativo" ? (
              <CheckCircleOutlined />
            ) : (
              <PlusCircleOutlined />
            )
          }
          color={situation === "Programa Ativo" ? "success" : "processing"}
          text={situation}
        />
      ),
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (date, record) => (
        <PrimaryAndSecondaryTextCell
          align="center"
          textAlign="center"
          primaryText={date}
          secondaryText={record.description}
        />
      ),
    },
    {
      title: "Ações",
      dataIndex: "address",
      key: "address",
      align: "center",
      render: () => (
        <Button type="primary" icon={<EyeOutlined />}>
          Monitorando
        </Button>
      ),
    },
  ];

  const data = [
    {
      id: "1",
      name: "Uma cesta básica",
      address: "Carteira da Criança",
      date: "20/11/2019",
      description: "Está no programa há aproximadamente 1 mês",
      situation: "Programa Ativo",
    },
    {
      id: "2",
      name: "Algum Benefício",
      address: "Inspira Boa Vista",
      date: "11/10/2019",
      description: "Está no programa há 18 dias",
      situation: "Programa Ativo",
    },
    {
      id: "3",
      name: "Outro Benefício",
      address: "COOPCAS",
      date: "17/09/2019",
      description: "Está no programa há 98 dias",
      situation: "Programa Suspenso",
    },
    {
      id: "4",
      name: "Uma cesta básica",
      address: "Carteira da Criança",
      date: "20/11/2019",
      description: "Está no programa há aproximadamente 1 mês",
      situation: "Programa Ativo",
    },
    {
      id: "5",
      name: "Algum Benefício",
      address: "Inspira Boa Vista",
      date: "11/10/2019",
      description: "Está no programa há 18 dias",
      situation: "Programa Ativo",
    },
    {
      id: "6",
      name: "Outro Benefício",
      address: "COOPCAS",
      date: "17/09/2019",
      description: "Está no programa há 98 dias",
      situation: "Programa Suspenso",
    },
  ];
  return (
    <Table pagination={false} tableColumns={columns} dataReceived={data} />
  );
}
