import React, { useContext, useEffect, useState } from "react";
// Libraries
import { Divider, Progress } from "antd";

// Contexts
import { ThemeContext } from "~/contexts/ThemeContext";
// Components
import Text from "~/components/typography/text";

// Own Components
import ProductServicesList from "./components/ProductServicesList";
import ShareDonation from "./components/ShareDonation";

import { WalletFormatConversion } from "~/utils/Transformations";

import {
  FlexBetweenRow,
  FlexCenterColumn,
  MainCard,
  CardHeader,
} from "./style";

export default function DonationDetails({ donation }) {
  const [progressBar, setProgressBar] = useState(0);
  const [collected, setCollected] = useState(0);
  const [donationGoal, setDonationGoal] = useState(0);

  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    let getCollected = 0;
    let getOnlyAlreadyDonated = 0;
    let totalProductServiceValue = 0;

    donation.product_services.forEach((productService) => {
      const { already_donated, value, quantity } = productService;

      const valueNumber = Number(value) * quantity;

      if (already_donated) {
        getCollected += valueNumber;
        getOnlyAlreadyDonated++;
      }

      totalProductServiceValue += valueNumber;
    });

    setDonationGoal(WalletFormatConversion(totalProductServiceValue));
    setCollected(WalletFormatConversion(getCollected));

    setProgressBar(
      (getOnlyAlreadyDonated / donation.product_services.length) * 100
    );
  }, [donation.product_services]);

  return (
    <MainCard shadow={theme.color.cardShadowBox}>
      <CardHeader>
        <Text
          strong
          style={{ textAlign: "center", fontSize: "24pt" }}
          color={theme.color.primary}
        >
          {collected}
        </Text>
        <Text style={{ textAlign: "center", fontSize: "18pt" }}>
          Arrecadado
        </Text>
      </CardHeader>
      <Progress
        style={{ padding: "0 20px" }}
        percent={progressBar.toFixed(0)}
        status="active"
        strokeColor={
          progressBar.toFixed(0) === 100
            ? theme.color.success
            : theme.color.primary
        }
        strokeWidth={15}
      />
      <FlexBetweenRow>
        <FlexCenterColumn>
          <Text strong style={{ fontSize: "16pt" }}>
            {donationGoal}
          </Text>
          <Text>Meta</Text>
        </FlexCenterColumn>
        <FlexCenterColumn>
          <Text strong style={{ fontSize: "16pt" }}>
            1654
          </Text>
          <Text>Transformadores</Text>
        </FlexCenterColumn>
        <FlexCenterColumn>
          <Text strong style={{ fontSize: "16pt" }}>
            14
          </Text>
          <Text>Dias restantes</Text>
        </FlexCenterColumn>
      </FlexBetweenRow>
      <Divider style={{ borderColor: theme.color.white }} />
      <ProductServicesList donation={donation} />
      <ShareDonation donationId={donation.id} />
    </MainCard>
  );
}
