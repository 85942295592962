import React, { useContext } from "react";

// Components
import { message } from "antd";
import Button from "~/components/button";
import Drawer from "~/components/drawer";
import Title from "~/components/typography/title";
import Text from "~/components/typography/text";

import { ThemeContext } from "~/contexts/ThemeContext";

import { GetBrandIcon } from "~/utils/SocialMeAssets";

import {
  ProductServicesButtonContainer,
  ProductServicesContent,
  TitleContent,
  Footer,
} from "./style";

import AddProductOrService from "./components/AddProductOrService";
import ProductServiceItem from "./components/ProductServicesItem";
import Products from "./components/Products";
import Services from "./components/Services";

export default function WhatDoYouWant({
  donation,
  GoBackOneStep,
  setDonation,
  NextStep,
  isDebug,
}) {
  const { theme } = useContext(ThemeContext);

  function GoNextStep() {
    if (donation.product_services.length > 0) {
      NextStep();
    } else {
      message.error("Adicionar algum produto ou serviço.");
    }
  }

  return (
    <ProductServicesButtonContainer>
      <Drawer
        title="Solicite produtos"
        placement="right"
        Trigger={
          <AddProductOrService
            image={GetBrandIcon("beneficiary")}
            text="Adicionar produto"
          />
        }
        DrawerContentComponent={Products}
        DrawerContentComponentParams={{
          donation,
          setDonation,
          isDebug,
        }}
      />

      <Drawer
        title="Solicite serviços"
        placement="right"
        Trigger={
          <AddProductOrService
            image={GetBrandIcon("marketplace")}
            text="Adicionar serviço"
          />
        }
        DrawerContentComponent={Services}
        DrawerContentComponentParams={{
          donation,
          setDonation,
          isDebug,
        }}
      />

      <ProductServicesContent>
        <Text strong>Meus Pedidos: </Text>
        {donation.product_services.length > 0 ? (
          donation.product_services.map((productService) => (
            <ProductServiceItem
              donation={donation}
              setDonation={setDonation}
              key={productService.id}
              theme={theme}
              productService={productService}
              isDebug={isDebug}
            />
          ))
        ) : (
          <TitleContent color={theme.color.primary}>
            <Title color={theme.color.primary} textAlign="center" level={4}>
              Não há nenhum produto ou serviço solicitado
            </Title>
          </TitleContent>
        )}
      </ProductServicesContent>

      <Footer>
        <Button onClick={GoBackOneStep}>Voltar</Button>
        <Button type="primary" onClick={GoNextStep}>
          Próxima Etapa
        </Button>
      </Footer>
    </ProductServicesButtonContainer>
  );
}
