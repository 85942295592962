import React, { memo } from 'react';

import Text from '~/components/typography/text';

import { Flex } from './style';

import { CapitalizeString } from '~/utils/Transformations';

function PrimaryAndSecondaryTextCell({
  primaryText,
  secondaryText,
  align,
  textAlign,
}) {
  return (
    <Flex direction="column" align={align}>
      <Text strong>{CapitalizeString(primaryText)}</Text>
      <Text>{CapitalizeString(secondaryText)}</Text>
    </Flex>
  );
}

export default memo(PrimaryAndSecondaryTextCell);
