import React, { useContext } from 'react';

import { Avatar } from 'antd';
import { CapitalizeString } from '~/utils/Transformations';

import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { AuthContext } from '~/contexts/AuthContext';

import Text from '~/components/typography/text';

import { Container, ListBox } from './style'

export default function ExpandableTableRow({ record }) {
  const { user } = useContext(AuthContext);

  const { isMobile } = useContext(ResponsiveContext)

  return (
    <Container>
      <ListBox>
        <Avatar
          style={isMobile ? { margin: "10px 0"} : { marginRight: '15px' }}
          size={32}
          src={record.activity_person_avatar}
        />
        <Text strong style={isMobile && { textAlign: "center"}}>
          {CapitalizeString(`Realizado por ${record.activity_person_name}`)}
        </Text>
      </ListBox>

      <ListBox>
        <Avatar style={isMobile ? { margin: "10px 0"} : { marginRight: '15px' }} size={32} src={null} />
        <Text strong style={isMobile && { textAlign: "center"}}>{CapitalizeString(`Local: ${record.place_name}`)}</Text>
      </ListBox>

      <ListBox>
        <Avatar
          style={isMobile ? { margin: "10px 0"} : { marginRight: '15px' }}
          size={32}
          src={record.created_by_person_avatar}
        />
        <Text strong style={isMobile && { textAlign: "center"}}>
          {user.id === record.person_id
            ? CapitalizeString(
                `Atividade registrada por ${record.created_by_person_name}`
              )
            : 'Atividade foi registrada por mim'}
        </Text>
      </ListBox>
    </Container>
  );
}
