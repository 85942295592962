import React, { useState, useContext } from "react";

import Button from "~/components/button";
import Drawer from "~/components/drawer";
import Text from "~/components/typography/text";

import SocialMeAPI from "~/utils/SocialMeAPI";

import { ResponsiveContext } from "~/contexts/ResponsiveContext";

import { WalletFormatConversion } from "~/utils/Transformations";

import EditProductOrService from "./components/EditProductOrService";

import { Container, ListItem, TextContent, DeleteIcon } from "./style";

export default function ProductServicesItem({
  productService,
  donation,
  setDonation,
  theme,
  isDebug,
}) {
  const [closeDrawer, setCloseDrawer] = useState(false);

  const {
    ps_kind,
    description,
    // ps_avatar,
    name,
    value,
    quantity,
    id,
  } = productService;

  const { isTablet, isMobile } = useContext(ResponsiveContext);

  async function DeleteProductOrService() {
    const params = {
      operation: ["Donation", "ProductServices", "Delete"],
      urlParams: {
        donationId: donation.id,
        productServiceId: id,
      },
    };

    if (isDebug) console.log("📦 Params: ", params);

    const response = await SocialMeAPI(params);

    if (isDebug)
      console.log(
        "🚀 API Response, Donation ProductServices Delete: ",
        response
      );

    if (response.success) {
      if (isDebug) console.log("Produto or serviçe was deleted");

      setDonation({
        ...donation,
        product_services: donation.product_services.filter(
          (item) => item.id !== id
        ),
      });
    } else if (isDebug) {
      console.log("No produto or serviçe was deleted");
    }
  }

  return (
    <Container
      borderColor={theme.color.borderColor}
      color={theme.color.textOnPrimaryBackground}
    >
      <ListItem width={isTablet ? "100%" : "50%"}>
        {/* <Avatar src={ps_avatar} size="50px" /> */}
        <TextContent>
          <Text strong style={{ textAlign: "left" }}>
            {`${ps_kind === "PRODUCT" ? "Produto:" : "Serviço:"} ${name}`}
          </Text>
          <Text style={{ textAlign: "left" }}>{description}</Text>
        </TextContent>
      </ListItem>

      <ListItem
        width={isTablet ? "50%" : isMobile ? "100%" : "25%"}
        column
        align="center"
      >
        <Text>{`Unidade: ${WalletFormatConversion(value)}`}</Text>
        <Text>{`Quantidade: ${quantity}`}</Text>
      </ListItem>

      <ListItem
        align="center"
        width={isTablet ? "50%" : isMobile ? "100%" : "25%"}
      >
        <Drawer
          title={`Editando ${ps_kind === "PRODUCT" ? "produto" : "serviço"}`}
          placement="right"
          Trigger={<Button>Editar</Button>}
          closeDrawer={closeDrawer}
          DrawerContentComponent={EditProductOrService}
          DrawerContentComponentParams={{
            donation,
            setDonation,
            productService,
            setCloseDrawer,
            isDebug,
          }}
        />
        <DeleteIcon size={32} onClick={DeleteProductOrService} />
      </ListItem>
    </Container>
  );
}
