import React, { useContext } from 'react';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';
// Pages
import BeneficiaryDescription from './BeneficiaryDescription';
import BeneficiaryDashboard from './BeneficiaryDashboard';

export default function AuthenticationCheck() {
  const { user } = useContext(AuthContext);
  return user ? <BeneficiaryDashboard /> : <BeneficiaryDescription />;
}
