import React from "react";

import Text from "~/components/typography/text";

import ProductServiceItem from "./components/ProductServiceItem";

export default function ProductsServices({ productServices, theme }) {
  return (
    <>
      <div
        style={{
          margin: "10px 0 20px 0",
        }}
      >
        <Text strong>Extrato: </Text>

        {productServices.map((productService) => (
          <ProductServiceItem
            key={productService.id}
            theme={theme}
            productService={productService}
          />
        ))}
      </div>
    </>
  );
}
