import React from "react";
// Libraries
import { Button, Badge } from "antd";
import {
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useLocation,
  Link,
} from "react-router-dom";

// Pages
import AvailablePrograms from "./pages/AvailablePrograms";
import InvitationsReceived from "./pages/InvitationsReceived";
import RequestsMade from "./pages/RequestsMade";
import RedemptionBenefits from "./pages/RedemptionBenefits";


export default function Opportunities() {
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 10,
        }}
      >
        <Badge count={3}>
          <Link to={`${path}/programas-disponiveis`}>
            <Button
              type={
                location.pathname === `${path}/programas-disponiveis`
                  ? "primary"
                  : "default"
              }
              shape="square"
              size="large"
            >
              Programas Disponíveis
            </Button>
          </Link>
        </Badge>
        <Badge count={2}>
          <Link to={`${path}/convites-recebidos`}>
            <Button
              type={
                location.pathname === `${path}/convites-recebidos`
                  ? "primary"
                  : "default"
              }
              shape="square"
              size="large"
            >
              Convites Recebidos
            </Button>
          </Link>
        </Badge>
        <Badge count={2}>
          <Link to={`${path}/solicitacoes-realizadas`}>
            <Button
              type={
                location.pathname === `${path}/solicitacoes-realizadas`
                  ? "primary"
                  : "default"
              }
              shape="square"
              size="large"
            >
              Solicitações Realizadas
            </Button>
          </Link>
        </Badge>
        <Badge count={3}>
          <Link to={`${path}/beneficios-para-resgate`}>
            <Button
              type={
                location.pathname === `${path}/beneficios-para-resgate`
                  ? "primary"
                  : "default"
              }
              shape="square"
              size="large"
            >
              Benefícios para Resgate
            </Button>
          </Link>
        </Badge>
      </div>

      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/programas-disponiveis`} />
        </Route>
        <Route
          path={`${path}/programas-disponiveis`}
          component={AvailablePrograms}
        />
        <Route
          path={`${path}/convites-recebidos`}
          component={InvitationsReceived}
        />
        <Route
          path={`${path}/solicitacoes-realizadas`}
          component={RequestsMade}
        />
        <Route
          path={`${path}/beneficios-para-resgate`}
          component={RedemptionBenefits}
        />
      </Switch>
    </div>
  );
}
