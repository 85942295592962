import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  border: 2px solid ${props => props.borderColor};
  flex-direction: row;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin: 10px 0;
  transition: all 0.4s;
  cursor: pointer;

  &:hover {
    border: 2px solid ${props => props.borderHoverColor};
  }
`;

export const Avatar = styled.img`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  margin-right: 20px;
`;
