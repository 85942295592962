const defaultAvatars = {
  feminine:
    "https://images.unsplash.com/photo-1520810627419-35e362c5dc07?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ",
  male:
    "https://images.unsplash.com/photo-1476900966873-ab290e38e3f7?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=fe0976a79ece0ee8effca4cab4527ae2",
  partner: "https://uifaces.co/our-content/donated/t33XAm04.jpg",
};

const brandsIcons = {
  accountManagement:
    "https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/account_64x64.png",
  operationsFinder:
    "https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/achei_64x64.png",
  digitalWallet:
    "https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/wallet_64x64.png",
  beneficiary:
    "https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/beneficiary_64x64.png",
  manager:
    "https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/manager_64x64.png",
  partner:
    "https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/provider_64x64.png",
  donor:
    "https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/giver_64x64.png",
  donationsPortal:
    "https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/doacoesMe_64x64.png",
  marketplace:
    "https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/feiraLivre_64x64.png",
  ecoop:
    "https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/ecoop_64x64.png",
  administration:
    "https://socialme-assets.s3.us-east-1.amazonaws.com/images/icons/modules/admin.svg",
  citizen:
    "https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/cidadao_64x64.png",
  medicalRecord:
    "https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/prontuario_64x64.png",
  socialMe:
    "https://socialme-assets.s3.us-east-1.amazonaws.com/images/brands/socialMe.png",
  socialMeVariation1:
    "https://socialme-assets.s3.amazonaws.com/images/brands/socialMe_variation1.svg",
  socialMeVariation2:
    "https://socialme-assets.s3.amazonaws.com/images/brands/socialMe_variation2.svg",
  socialMeVariation3:
    "https://socialme-assets.s3.amazonaws.com/images/brands/socialMe_variation3.svg",
  socialMeVariation4:
    "https://socialme-assets.s3.amazonaws.com/images/brands/socialMe_variation4.svg",
  socialMeVariation5:
    "https://socialme-assets.s3.amazonaws.com/images/brands/socialMe_variation5.svg",
  socialMeVariation6:
    "https://socialme-assets.s3.amazonaws.com/images/brands/socialMe_variation6.svg",
};

export function GetBrandIcon(name) {
  return brandsIcons[name];
}

export function GetDefaultAvatar(name) {
  return defaultAvatars[name];
}
