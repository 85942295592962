import React, { useContext, memo } from "react";

import { ResponsiveContext } from "~/contexts/ResponsiveContext";
import { ThemeContext } from "~/contexts/ThemeContext";

import { TextInput, OptionsContainer } from "./style";

function InputDynamicComplete({
  disabled,
  placeholder,
  size,
  type,
  prefix,
  width,
  height,
  margin,
  value,
  onSelect,
  options,
  onChange,
  OptionComponent,
  OptionComponentProps,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <TextInput
        disabled={!!disabled}
        size={isMobile ? "large" : size ? size : "default"}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type ? type : "text"}
        prefix={prefix ? prefix : false}
        margin={margin}
        width={width}
        color={theme.color.textOnSecondaryBackground}
        backgroundcolor={theme.color.backgroundSecondary}
      />
      <OptionsContainer
        height={height || 500}
        scrollColor={theme.color.primary}
        borderColor={theme.color.white}
        display={options.length > 0 ? "block" : "none"}
      >
        {options.map((option) => (
          <OptionComponent
            key={option.id}
            theme={theme}
            onSelect={onSelect}
            option={option}
          />
        ))}
      </OptionsContainer>
    </>
  );
}

// <Item
//   onClick={() => onSelect(option)}
//   borderColor={theme.color.white}
//   borderHoverColor={theme.color.primary}
//   key={option.id}
// >
//   <Avatar src={option.avatar} />
//   <TextContainer>
//     <Text strong textAlign="left">
//       {CapitalizeString(option.name)}
//     </Text>
//     <Text textAlign="left">{`O nome cultural desta coisa (NCM: ${option.ncm})`}</Text>
//   </TextContainer>
// </Item>

export default memo(InputDynamicComplete);
