import API from "~/environments/APIs";

const ProductService = {
  Search: {
    baseURL: API.socialMe,
    url: "/prod-serv/ps-models",
    method: "post",
    timeout: "50000",
  },
  Show: {
    baseURL: API.socialMe,
    url: "/prod-serv/ps-model/product-serviceId",
    method: "post",
    timeout: "50000",
  },
  Add: {
    baseURL: API.socialMe,
    url: "/manage/person/personId/offering",
    method: "post",
    timeout: "50000",
  },
  Edit: {
    baseURL: API.socialMe,
    url: "/donation/request/donationId/product-service/productServiceId",
    method: "put",
    timeout: "50000",
  },
  Delete: {
    baseURL: API.socialMe,
    url: "/manage/person/personId/offering/offeringId",
    method: "delete",
    timeout: "50000",
  },
  UnitsOfMeasure: {
    Get: {
      baseURL: API.socialMe,
      url: "/prod-serv/m-unities",
      method: "post",
      timeout: "50000",
    },
  },
  // manage/person/personId/offering/offeringId
  /* 
    tag: {
      add: []
      sub: []
    }
  */
};

export default ProductService;
