// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Roboto-Medium.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face {\n  font-family: RobotoMedium;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n* {\n  font-family: RobotoMedium !important;\n  -webkit-font-smoothing: antialiased;\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.ex {\n  transition-delay: 0s;\n  transition: all 0.5s;\n  -webkit-transition: all 0.5s;\n}\n\n.ant-form-item-explain {\n  margin: 0px !important;\n}\n/* INPUTS */\n.ant-input {\n  background-color: transparent !important;\n  /* color: inherit !important; */\n}\ninput[type=\"password\"] {\n  color: inherit !important;\n}\ninput[type=\"text\"] {\n  color: inherit !important;\n}\n.ant-input-password-icon {\n  color: inherit !important;\n}\n.ant-input[disabled] {\n  color: inherit !important;\n}\n/* SELECTS */\n.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {\n  background-color: inherit !important;\n}\n.ant-select-arrow {\n  color: inherit !important;\n}\n.ant-select-selector {\n  border-radius: 8px !important;\n  text-align: left !important;\n}\n\n/* MODAL */\n\n.ant-modal-content {\n  border-radius: 8px !important;\n  width: 100%;\n}\n.ant-modal-body {\n  padding: 10px !important;\n}\n.ant-modal-header {\n  border-radius: 8px 8px 0 0 !important;\n}\n\n/* BODY LAYOUT */\n.ant-layout-content::-webkit-scrollbar {\n  width: 6px;\n}\n", ""]);
// Exports
module.exports = exports;
