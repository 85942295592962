import React from "react";
// Components
import Title from "~/components/typography/title";
import Button from "~/components/button";
// Environments
import System from "~/environments/System";
// Utils
import { GetModuleNameByHostname } from "~/utils/Hosts";

import { Container, Width, MarginBottom, Content, Div } from "./style";

export default function UserDisconnectedDescription({ description }) {
  return (
    <Container>
      <Width>
        <MarginBottom />

        <Div>
          <Title level={4} style={{ textAlign: "center" }}>
            {description}
          </Title>
        </Div>
        <Content>
          <a
            href={`https://${
              System.hostname.authentication
            }/?service=${GetModuleNameByHostname()}`}
            style={{
              width: 220,
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
            }}
          >
            <Button type="primary" size="large">
              Entrar no Social Me
            </Button>
          </a>
          <a
            href={`https://${
              System.hostname.authentication
            }/?service=${GetModuleNameByHostname()}`}
            style={{
              width: 220,
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
            }}
          >
            <Button type="link" style={{ marginTop: 10 }}>
              Criar uma conta Social Me
            </Button>
          </a>
        </Content>
      </Width>
    </Container>
  );
}
