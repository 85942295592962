import styled from "styled-components";

import System from "~/environments/System";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 15px;
`;
