import React, { useContext, useState } from 'react';

// Components
import { Drawer } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';

import QRCodeReader from './components/QrReader';
import Description from './components/Description';

export default function QRCodeDrawer({
  CloseDrawer,
  Component,
  qrCodeType,
  qrCodeDescription,
  qrCodeTitle,
  visibility,
}) {
  const [QRCodeInfo, setQRCodeInfo] = useState([]);
  const [QRCodeError, setQRCodeError] = useState(null);

  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  function HandleCloseDrawer() {
    CloseDrawer();
    setQRCodeInfo([]);
  }

  return (
    <Drawer
      width={`${isMobile ? '100vw' : '30vw'}`}
      closable={false}
      title={
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ArrowLeftOutlined
            onClick={HandleCloseDrawer}
            style={{
              color: theme.color.textOnPrimaryBackground,
              cursor: 'pointer',
            }}
          />
          <span
            style={{
              color: theme.color.textOnPrimaryBackground,
              marginLeft: 'auto',
            }}
          >
            {qrCodeTitle}
          </span>
        </div>
      }
      placement="right"
      headerStyle={{
        backgroundColor: theme.color.backgroundPrimary,
      }}
      bodyStyle={{
        padding: 0,
        overflowY: 'hidden',
        maxHeight: 'calc(100vh - 55px)',
        backgroundColor: theme.color.backgroundPrimaryDark,
      }}
      visible={visibility}
      key="id"
    >
      {QRCodeInfo.length === 0 ? (
        <>
          {QRCodeError ? (
            <Description
              icon={true}
              color={theme.color.danger}
              text={'QRCode Inválido'}
            />
          ) : (
            <Description
              color={theme.color.textOnPrimaryBackground}
              text={qrCodeDescription}
            />
          )}
          <QRCodeReader
            QRCodeType={qrCodeType}
            setQRCodeError={setQRCodeError}
            setQRCodeInfo={setQRCodeInfo}
          />
        </>
      ) : (
        <Component.mount
          CloseDrawer={CloseDrawer}
          setQRCodeInfo={setQRCodeInfo}
          QRCodeInfo={QRCodeInfo}
          realoadActivitysFromAPI={Component.actions.LoadActivitysFromAPI}
        />
      )}
    </Drawer>
  );
}
