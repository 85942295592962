import React from "react";
// Libraries
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
// Components
import { Card, Typography, Button, Divider } from "antd";
// import Table from './components/Table';
import Breadcrumb from "~/components/breadcrumb";

const { Title } = Typography;

const breadcrumbItems = [
  {
    title: "Painel do Beneficiário",
  },
  {
    title: "Minhas atividades",
    url: "/atividades",
  },
  {
    title: "Nova atividade",
  },
];

export default function AddActivities() {
  // Variables
  return (
    <div>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title level={4}>Nova Atividade</Title>
          <Link to="/atividades">
            <Button type="dashed" icon={<ArrowLeftOutlined />}>
              Voltar
            </Button>
          </Link>
        </div>
        <Divider />

        <div
          style={{
            height: 300,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title level={4} style={{ color: "#bdbdbd" }}>
            Planejar Tela
          </Title>
        </div>
      </Card>
    </div>
  );
}
