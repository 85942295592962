export default {
  SM00EF13A: {
    message: 'Browser token successfully created',
  },
  SM9DC07A7: {
    message: 'Successfully registered person',
  },
  SME60A688: {
    message: "The 'activity_frequency' was successfully created",
  },
  SMAA74180: {
    message: "The 'donation request' was successfully created",
  },
  SMDD65D86: {
    message: "The 'donation request product/service' was successfully created",
  },
 
};
