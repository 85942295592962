import React from "react";

import { WarningFilled } from "@ant-design/icons";

import Text from "~/components/typography/text";

import { Container } from "./style";

export default function NegationWarningModal({ theme, description }) {
  return (
    <Container>
      <WarningFilled
        style={{ color: theme.color.orange, fontSize: 36, marginRight: 15 }}
      />
      <Text
        color={theme.color.textOnSecondaryBackground}
        style={{ fontSize: 16 }}
      >
        {description}
      </Text>
    </Container>
  );
}
