import System from "~/environments/System";

export function GetModuleNameByHostname() {
  const hostname = window.location.hostname;

  for (const key in System.hostname) {
    if (System.hostname[key] === hostname) return key;
  }
  return "localhost";
}
