import React from "react";

import Card from "~/components/card";

import TalkAboutYou from "./components/TalkAboutYou";
import WhatDoYouWant from "./components/WhatDoYouWant";
import Purpose from "./components/Purpose";
import SocialComplement from "./components/SocialComplement";

import Debug from "~/environments/Debug";

export default function DonationSteps({
  donation,
  setDonation,
  setDonationType,
  setDonationId,
  currentStep,
  setCurrentStep,
  kind,
  GoBackOneStep,
  NextStep,
}) {
  const isDebug = Debug.Beneficiary.Donation;
  const RenderStep = () => {
    switch (currentStep) {
      default:
      case 0:
        return (
          <TalkAboutYou
            setDonationType={setDonationType}
            kind={kind}
            donation={donation}
            setDonation={setDonation}
            setDonationId={setDonationId}
            NextStep={NextStep}
            isDebug={isDebug}
          />
        );
      case 1:
        return (
          <WhatDoYouWant
            setDonation={setDonation}
            donation={donation}
            setCurrentStep={setCurrentStep}
            GoBackOneStep={GoBackOneStep}
            NextStep={NextStep}
            isDebug={isDebug}
          />
        );

      case 2:
        return (
          <Purpose
            setDonation={setDonation}
            donation={donation}
            setCurrentStep={setCurrentStep}
            GoBackOneStep={GoBackOneStep}
            NextStep={NextStep}
            isDebug={isDebug}
          />
        );
      case 3:
        return (
          <SocialComplement
            donation={donation}
            setDonation={setDonation}
            setCurrentStep={setCurrentStep}
            GoBackOneStep={GoBackOneStep}
            isDebug={isDebug}
          />
        );
    }
  };

  return (
    <>
      <Card
        bodyStyle={{
          padding: "10px",
        }}
      >
        {RenderStep()}
      </Card>
    </>
  );
}
