import React, { useState, useEffect, useContext } from "react";

// Libraries
import { Link, useRouteMatch } from "react-router-dom";

import { Card, Empty } from "antd";

import SocialMeAPI from "~/utils/SocialMeAPI";

import { ResponsiveContext } from "~/contexts/ResponsiveContext";
import { ThemeContext } from "~/contexts/ThemeContext";

import Pagination from "~/components/pagination";

import Button from "~/components/button";
import Loading from "~/components/loading";
import Divider from "~/components/divider";
import Title from "~/components/typography/title";

import DonationTable from "./components/DonationTable";
import Filters from "./components/Filters";

import Debug from "~/environments/Debug";

import { Container } from "./style";

export default function MyRequests() {
  const [donations, setDonations] = useState([]);
  const [donationCount, setDonationCount] = useState({});

  const [totalItems, setTotalItems] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [donationStatus, setDonationStatus] = useState({
    type: "complete",
    isComplete: false,
    isResolved: false,
    isPublished: false,
  });

  const { path } = useRouteMatch();
  const [loading, setLoading] = useState(true);

  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  const isDebug = Debug.Beneficiary.Donation;

  useEffect(() => {
    async function GetDonationStatusCount() {
      setLoading(true);

      const params = {
        operation: ["Donation", "GetMy"],
      };

      if (isDebug) console.log("📦 Params: ", params);

      const response = await SocialMeAPI(params);

      if (isDebug) console.log("🚀 API Response, Donation GetMy: ", response);

      if (response.success) {
        if (isDebug) console.log("The donations count of each step!");

        const getDonations = response.body.donationRequests.elements;

        const count = { rejected: 0, published: 0, complete: 0, incomplete: 0 };

        getDonations.forEach((donation) => {
          const { is_complete, is_published, is_resolved } = donation;
          if (is_published) {
            count.published += 1;
          } else if (is_complete && is_resolved) {
            count.rejected += 1;
          } else if (is_complete) {
            count.complete += 1;
          } else if (!is_complete && !is_published) {
            count.incomplete += 1;
          }
        });

        setDonationCount(count);
      } else if (isDebug) {
        console.log("Cannot get the number of rejected donations");
      }

      setLoading(false);
    }
    GetDonationStatusCount();
  }, [isDebug]);

  useEffect(() => {
    async function GetMyDonations() {
      setLoading(true);
      const { isComplete, isResolved, isPublished } = donationStatus;

      const params = {
        operation: ["Donation", "GetMy"],
        data: {
          filter: {
            isComplete,
            isResolved,
            isPublished,
          },
          pagination: {
            limit,
            page: currentPage,
          },
        },
      };

      if (isDebug) console.log("📦 Params: ", params);

      const response = await SocialMeAPI(params);

      if (isDebug) console.log("🚀 API Response, Donation GetMy: ", response);

      if (response.success) {
        if (isDebug) console.log("Donations found!");

        setDonations(response.body.donationRequests.elements);
        setTotalItems(response.body.donationRequests.totalElements);
      } else if (isDebug) {
        console.log("No donations were listed");
      }

      setLoading(false);
    }
    GetMyDonations();
  }, [currentPage, limit, donationStatus, isDebug]);

  return (
    <Card
      style={{
        overflow: "auto",
      }}
      bodyStyle={{
        padding: isMobile ? 5 : 24,
      }}
    >
      <Container>
        <Title level={4}>Meus Pedidos de Doações</Title>
        <Link
          style={{ marginTop: isMobile ? 20 : 0 }}
          to={`${path}/solicitar-doacao`}
        >
          <Button type="primary">Pedir doação</Button>
        </Link>
      </Container>
      <Divider borderColor={theme.color.white} />
      <Filters
        theme={theme}
        donationStatus={donationStatus}
        donationCount={donationCount}
        setCurrentPage={setCurrentPage}
        setLimit={setLimit}
        setDonationStatus={setDonationStatus}
      />
      {loading ? (
        <Loading />
      ) : donations.length === 0 ? (
        <Empty description="Nenhuma doação foi encontrada" />
      ) : (
        <>
          <DonationTable
            theme={theme}
            emptyDescription="Nenhuma doação foi feita"
            donations={donations}
          />
          <Pagination
            onChange={(page) => setCurrentPage(page)}
            onShowSizeChange={(current, size) => setLimit(size)}
            total={totalItems}
            limit={limit}
            currentPage={currentPage}
          />
        </>
      )}
    </Card>
  );
}
