import API from "~/environments/APIs";

const Beneficiery = {
  Add: {
    baseURL: API.socialMe,
    url: "person/activity/activityId/activity-frequency",
    method: "post",
    timeout: "5000",
  },
  Get: {
    baseURL: API.socialMe,
    url: "/person/activity/activity-frequencies",
    method: "post",
    timeout: "5000",
  },
  Moves: {
    baseURL: API.socialMe,
    url: "/dw/account/balance/detailed/accountType",
    method: "post",
    timeout: "5000",
  },
  ManualDeposits: {
    List: {
      baseURL: API.socialMe,
      url: "/dw/operation/manuals",
      method: "post",
      timeout: "5000",
    },
    Get: {
      baseURL: API.socialMe,
      url: "/dw/operation/manuals",
      method: "post",
      timeout: "5000",
    },
    Edit: {
      baseURL: API.socialMe,
      url: "/dw/operation/manuals",
      method: "post",
      timeout: "5000",
    },
    Confirm: {
      baseURL: API.socialMe,
      url: "/dw/operation/manuals",
      method: "post",
      timeout: "5000",
    },
  },
};

export default Beneficiery;
