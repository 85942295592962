import React, { useContext } from "react";
// Librarys";

import { Container, Avatar } from "./style";

// Contexts
import { ThemeContext } from "~/contexts/ThemeContext";

// Components
import Text from "~/components/typography/text";

export default function ProductServiceButton({ image, text }) {
  const { theme } = useContext(ThemeContext);

  return (
    <Container borderColor={theme.color.borderColor} borderHoverColor={theme.color.primary} >
      <Avatar src={image} size={36} />
      <Text color={theme.color.primary} strong>
        {text}
      </Text>
    </Container>
  );
}
