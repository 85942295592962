import React from "react";

import Text from "~/components/typography/text";

import { CapitalizeString } from "~/utils/Transformations";

import { Item, TextContainer } from "./style";

export default function ServiceItem({ onSelect, theme, option }) {
  return (
    <Item
      onClick={() => onSelect(option)}
      borderColor={theme.color.white}
      borderHoverColor={theme.color.primary}
      key={option.id}
    >
      {/* <Avatar src={option.avatar} /> */}
      <TextContainer>
        <Text strong style={{ textAlign: "left" }}>
          {CapitalizeString(option.name)}
        </Text>
        <Text
          style={{ textAlign: "left" }}
        >{`Catagoria: ${option.category}`}</Text>
      </TextContainer>
    </Item>
  );
}
