import React, { useContext, memo } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//Libraries
import { AutoComplete } from 'antd';

function AntAutoComplete({
  options,
  onChange,
  value,
  onSelect,
  onSearch,
  filterOption,
  size,
  children,
  style,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <AutoComplete
      size={size || isMobile ? 'large' : 'default'}
      options={options}
      value={value}
      onChange={onChange}
      onSelect={onSelect}
      onSearch={onSearch}
      filterOption={filterOption}
      style={style || {}}
    >
      {children}
    </AutoComplete>
  );
}

export default memo(AntAutoComplete);
