import styled from "styled-components";

export const Label = styled.label`
  box-sizing: border-box !important;
  background-image: url(${(props) => props.preview});
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  border-radius: 10px;
  background-color: var(--secondary-color);
  font-size: 15px;
  margin: 0 auto 10px auto;
  padding: 12px 4px;
  min-width: ${(props) => `${props.width}px`};
  min-height: ${(props) => `${props.height}px`};

  display: flex;
  align-items: center;
  justify-content: center;

  border: ${(props) => props.border};

  transition: 0.5s all;
  cursor: pointer;

  &:hover {
    border-color: ${(props) => props.hoverColor};
    color: ${(props) => props.hoverColor};
  }

  @media (max-width: 768px) {
    padding: 5px;

    margin: 0;
  }
`;

export const Input = styled.input`
  display: none;
`;
