import API from '~/environments/APIs';

const QRCode = {
  Decode: {
    baseURL: API.socialMe,
    url: '/qr-code/decode/qrcodeId',
    method: 'get',
    timeout: '5000',
  },
  Add: {
    baseURL: API.socialMe,
    url: 'person/activity/activityId/activity-frequency',
    method: 'post',
    timeout: '5000',
  },
};

export default QRCode;
