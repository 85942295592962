import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
