import React from "react";

// Components
import { Form } from "antd";
import Button from "~/components/button";
import TextArea from "~/components/textArea";
import FormItem from "~/components/form/FormItem";
import SocialMeAPI from "~/utils/SocialMeAPI";

import { FlexColumn, ButtonContainer } from "./style";

export default function Purpose({
  donation,
  setDonation,
  GoBackOneStep,
  NextStep,
  isDebug,
}) {
  async function EditDonationAddPurpose(values) {
    const params = {
      operation: ["Donation", "Edit"],
      urlParams: {
        donationId: donation.id,
      },
      data: values,
    };

    if (isDebug) console.log("📦 Params: ", params);

    const response = await SocialMeAPI(params);

    if (isDebug) console.log("🚀 API Response, Donation Edit: ", response);

    if (response.success) {
      if (isDebug) console.log("Donation purpose was edited");

      setDonation({ ...donation, help: values.help });
      NextStep();
    } else if (isDebug) {
      console.log("No donation purpose was edited");
    }
  }

  return (
    <FlexColumn>
      <Form
        layout="vertical"
        initialValues={donation}
        onFinish={EditDonationAddPurpose}
      >
        <FormItem
          name={["help"]}
          label="Como esta doação irá lhe ajudar?"
          rules={[
            {
              required: true,
              message: "Nos diga o seu propósito",
            },
          ]}
          item={<TextArea />}
        />

        <FormItem
          item={
            <ButtonContainer>
              <Button onClick={GoBackOneStep}>Voltar</Button>
              <Button htmlType="submit" type="primary">
                {`${
                  donation.help !== null
                    ? "Atualizar e Prosseguir"
                    : "Próxima Etapa"
                }`}
              </Button>
            </ButtonContainer>
          }
        />
      </Form>
    </FlexColumn>
  );
}
