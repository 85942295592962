import styled from "styled-components";

export const Item = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.borderColor};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 5px 15px;
  transition: all 0.5s;

  &:hover {
    border: 1px solid ${(props) => props.borderHoverColor};
  }
`;

export const Avatar = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-right: 15px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
