import React from "react";

import Table from "~/components/table";
import Button from "~/components/button";
import Badge from "~/components/badge";
import { ExternalLink } from "~/components/link";
import System from "~/environments/System";

import { PrimaryTextCell, ImageWithTextCell } from "~/components/table/cells";

import {
  WalletFormatConversion,
  CapitalizeString,
} from "~/utils/Transformations";

export default function MovesTable({ moves }) {
  const columns = [
    {
      title: "Doador",
      dataIndex: "giver_person_name",
      key: "giver_person_name",
      render: (giver_person_name, record) =>
        record.anonymous ? (
          <PrimaryTextCell text="Anônimo" />
        ) : (
          <ImageWithTextCell
            text={
              CapitalizeString(record.giver_person_social_name) ||
              CapitalizeString(giver_person_name)
            }
            src={record.giver_person_avatar}
            size={40}
          />
        ),
    },

    {
      title: "Item obtido",
      dataIndex: "donation_product_service_name",
      key: "donation_product_service_name",
      align: "center",
      render: (donation_product_service_name, record) => (
        <PrimaryTextCell
          textAlign="center"
          text={
            donation_product_service_name || record.product_service_model_name
          }
        />
      ),
    },
    {
      title: "Valor recebido",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (amount) => (
        <PrimaryTextCell
          textAlign="center"
          text={WalletFormatConversion(amount)}
        />
      ),
    },
    {
      title: "Créditos",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      render: (_id) =>
        false ? (
          <Badge status="success" text="Recebido" />
        ) : (
          <ExternalLink to={System.hostname.digitalWallet}>
            <Button>Receber créditos</Button>
          </ExternalLink>
        ),
    },
  ];

  return <Table tableColumns={columns} dataReceived={moves} />;
}
