import React from "react";
// Libraries

import { EyeOutlined } from "@ant-design/icons";

import Table from "~/components/table";
import Button from "~/components/button";
import Drawer from "~/components/drawer";
// Functions
import {
  ImageWithPrimaryAndSecondaryTextCell,
  DateAndTimeCell,
} from "~/components/table/cells";

// Own components
import ExpandableTableRow from "./components/ExpandebleTableRow";

export default function ActivitiesTable({ activitys }) {
  const columns = [
    {
      title: "Registrado em",
      dataIndex: "created_at",
      key: "created_at",
      width: "25%",
      render: (created_at) => <DateAndTimeCell timestamp={created_at} />,
    },
    {
      title: "Atividade",
      dataIndex: "activity_name",
      key: "activity_name",
      width: "50%",
      render: (activity_name, record) => (
        <ImageWithPrimaryAndSecondaryTextCell
          image={record.activity_avatar}
          primaryText={activity_name}
          secondaryText={record.activity_type_name}
        />
      ),
    },
    {
      title: "Ações",
      dataIndex: "id",
      key: "id",
      width: "25%",
      render: (id) => (
        <Drawer
          title="Detalhes da Atividade"
          Trigger={<Button text="Mais informações" icon={<EyeOutlined />} />}
          DrawerContentComponent={Button}
          DrawerContentComponentParams={{ id }}
        />
      ),
    },
  ];

  return (
    <Table
      tableColumns={columns}
      expandable={{
        expandedRowRender: (record) => <ExpandableTableRow record={record} />,
      }}
      dataReceived={activitys}
    />
  );
}
