import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20;
  min-height: calc(100vh - 64px);
`;

export const Width = styled.div`
  width: 90%;
`;

export const MarginBottom = styled.div`
  margin-bottom: 20;
`;

export const Div = styled.div``;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  flex-direction: column;
  width: 200px;
`;
