import Auth from "./auth";
import Donation from "./donation";
import Person from "./person";
import ProductService from "./product";
import Profile from "./profile";
import System from "./system";
import QRCode from "./qrcode";
import Beneficiery from "./beneficiery";

const Request = {
  Auth,
  Donation,
  Person,
  ProductService,
  Profile,
  System,
  QRCode,
  Beneficiery,
};

export default Request;
