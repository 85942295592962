import React from "react";

// Components
import { Form } from "antd";
import Button from "~/components/button";
import TextArea from "~/components/textArea";

import SocialMeAPI from "~/utils/SocialMeAPI";

import FormItem from "~/components/form/FormItem";

import { FlexColumn, ButtonContainer } from "./style";

export default function TalkAboutYou({
  donation,
  setDonationType,
  setDonationId,
  setDonation,
  kind,
  NextStep,
  isDebug,
}) {
  async function CreateDonation(values) {
    const params = {
      operation: ["Donation", "Add"],
      data: {
        ...values,
        kind,
      },
    };

    if (isDebug) console.log("📦 Params: ", params);

    const response = await SocialMeAPI(params);

    if (isDebug) console.log("🚀 API Response, Donation Add: ", response);

    if (response.success) {
      if (isDebug) console.log("The donation was created successfully");
      setDonationId(response.body.newDonationRequest.id);
      NextStep();
    } else if (isDebug) {
      console.log("Donation was not created");
    }
  }

  async function EditDonation(values) {
    const params = {
      operation: ["Donation", "Edit"],
      urlParams: {
        donationId: donation.id,
      },
      data: {
        ...values,
        kind,
      },
    };

    if (isDebug) console.log("📦 Params: ", params);

    const response = await SocialMeAPI(params);

    if (isDebug) console.log("🚀 API Response, Donation Edit: ", response);

    if (response.success) {
      if (isDebug) console.log("The donation was updated");
      const { dream, history, name, kind } = response.body.donationRequest;
      setDonation({ ...donation, dream, history, name, kind });
      NextStep();
    } else if (isDebug) {
      console.log("Donation was not updated");
    }
  }

  async function HandleOnSubmit(values) {
    if (donation.id !== undefined) {
      EditDonation(values);
    } else {
      CreateDonation(values);
    }
  }

  return (
    <FlexColumn>
      <Form
        layout="vertical"
        initialValues={donation}
        onFinish={HandleOnSubmit}
      >
        <FormItem
          name={["name"]}
          rules={[
            {
              required: true,
              message: "È importante que você tenha um nome para sua doação.",
            },
          ]}
          label="Qual o nome que você gostaria de chamar esta doação?"
          item={<TextArea />}
        />

        <FormItem
          name={["history"]}
          rules={[
            {
              required: true,
              message: "È importante que você conte um pouco.",
            },
          ]}
          label="Conte um pouco sobre a sua história"
          item={<TextArea />}
        />

        <FormItem
          name={["dream"]}
          rules={[
            {
              required: true,
              message: "Por favos, diga seu sonho",
            },
          ]}
          label="Conte sobre o seu maior sonho"
          item={<TextArea />}
        />

        <FormItem
          item={
            <ButtonContainer>
              <Button onClick={setDonationType}>Voltar</Button>
              <Button htmlType="submit" type="primary">
                {`${
                  donation.id !== undefined
                    ? "Atualizar e Prosseguir"
                    : "Próxima Etapa"
                }`}
              </Button>
            </ButtonContainer>
          }
        />
      </Form>
    </FlexColumn>
  );
}
