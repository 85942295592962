import styled from "styled-components";

import System from "~/environments/System";

export const Main = styled.div`
  background-color: ${(props) => props.backgroundColor};
`;

export const Body = styled.div`
  margin-top: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: 35% 64%;

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    display: block;
    margin: 0;
  }
`;
