import React, { useState, useEffect, useContext } from "react";
// Libraries

import { useParams, Link } from "react-router-dom";

import DonationTypes from "./components/DonationTypes";
import DonationSteps from "./components/DonationSteps";
import DonationProgress from "./components/DonationProgress";

import Loading from "~/components/loading";
import Button from "~/components/button";
import Card from "~/components/card";
import Title from "~/components/typography/title";

import { ResponsiveContext } from "~/contexts/ResponsiveContext";
import { ThemeContext } from "~/contexts/ThemeContext";

import SocialMeAPI from "~/utils/SocialMeAPI";

import Debug from "~/environments/Debug";

import { Body, Main } from "./style";

export default function RequestDonation() {
  const [kind, setKind] = useState("");
  const [donationType, setDonationType] = useState(false);
  const [donationId, setDonationId] = useState(useParams().donationId);
  const [donation, setDonation] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  const isDebug = Debug.Beneficiary.Donation;

  useEffect(() => {
    async function LoadDonation() {
      if (donationId) {
        setDonationType(true);
        setLoading(true);

        const params = {
          operation: ["Donation", "Show"],
          urlParams: {
            donationId,
          },
        };

        if (isDebug) console.log("📦 Params: ", params);

        SocialMeAPI(params).then((response) => {
          if (isDebug)
            console.log("🚀 API Response, Donation Show: ", response);

          if (response.success) {
            if (isDebug) console.log("Donation found!");
            setDonation(response.body.donationRequest);
            setKind(response.body.donationRequest.kind);
            setLoading(false);
          } else if (isDebug) {
            console.log("No donation were listed");
          }
        });
      }
    }

    LoadDonation();
  }, [donationId, isDebug]);

  function GoBackOneStep() {
    setCurrentStep(currentStep - 1);
  }

  function NextStep() {
    setCurrentStep(currentStep + 1);
  }

  const hasId = useParams().donationId;

  return (
    <Main backgroundColor={theme.color.backgroundSecondary}>
      {!donationType ? (
        <DonationTypes
          kind={kind}
          setKind={setKind}
          setDonationType={() => setDonationType(true)}
        />
      ) : loading ? (
        <Loading />
      ) : (
        <>
          <Card
            style={isMobile ? { marginBottom: 15 } : {}}
            bodyStyle={
              isMobile
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: 100,
                    padding: 10,
                  }
                : {
                    display: "flex",
                    padding: 20,
                  }
            }
          >
            <Title textAlign="left" level={4}>
              {hasId === undefined
                ? "Criando uma doação"
                : "Editando uma doação"}
            </Title>
            <Link style={isMobile ? {} : { marginLeft: "auto" }} to="/doacoes">
              <Button type="primary">Ver meus pedidos doações</Button>
            </Link>
          </Card>
          <Body>
            <DonationProgress
              isMobile={isMobile}
              donation={donation}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
            <DonationSteps
              donation={donation}
              setDonation={setDonation}
              setDonationType={() => setDonationType(false)}
              setDonationId={setDonationId}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              kind={kind}
              GoBackOneStep={GoBackOneStep}
              NextStep={NextStep}
            />
          </Body>
        </>
      )}
    </Main>
  );
}
