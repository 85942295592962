import React, { useEffect, useState } from "react";

import { Steps } from "antd";
import Card from "~/components/card";

const { Step } = Steps;

export default function DonationProgress({
  isMobile,
  currentStep,
  setCurrentStep,
  donation,
}) {
  const [firstStep, setFirstStep] = useState("process");
  const [secondStep, setSecondStep] = useState({
    status: "wait",
    disabled: true,
  });
  const [thirdStep, setThirdStep] = useState({
    status: "wait",
    disabled: true,
  });
  const [fourStep, setFourStep] = useState({
    status: "wait",
    disabled: true,
  });

  useEffect(() => {
    if (donation.id !== undefined) {
      const { help, product_services, history, dream } = donation;

      if (history !== undefined && dream !== undefined) {
        if (currentStep !== 0) {
          setFirstStep("finish");
        }
      } else {
        setSecondStep({ status: "wait", disabled: true });
      }

      if (product_services.length > 0) {
        setSecondStep({ status: "finish", disabled: false });
        setThirdStep({ status: "wait", disabled: false });
      } else {
        setSecondStep({ status: "wait", disabled: false });
        setThirdStep({ status: "wait", disabled: true });
        setFourStep({ status: "wait", disabled: true });
      }

      if (help !== null) {
        setThirdStep({ status: "finish", disabled: false });
        if (product_services.length > 0) {
          setFourStep({ status: "wait", disabled: false });
        }
      }
    }
  }, [donation, currentStep, thirdStep.disabled]);

  useEffect(() => {
    if (currentStep === 0) {
      setFirstStep("process");
    } else if (currentStep === 1) {
      setSecondStep({ status: "process", disabled: false });
    } else if (currentStep === 2) {
      setThirdStep({ status: "process", disabled: false });
    } else if (currentStep === 3) {
      setFourStep("process");
    }
  }, [donation, currentStep]);

  return (
    <Card style={isMobile ? { marginBottom: 15 } : {}} >
      <Steps
        current={currentStep}
        onChange={setCurrentStep}
        direction="vertical"
      >
        <Step
          status={firstStep}
          title="Conte Um Pouco Sobre Você"
          style={{
            marginBottom: "20px",
          }}
        />
        <Step
          status={secondStep.status}
          disabled={secondStep.disabled}
          title="Do Que Você Precisa?"
          style={{
            margin: "20px 0",
          }}
        />
        <Step
          status={thirdStep.status}
          disabled={thirdStep.disabled}
          title="Qual Seu Propósito?"
          style={{
            margin: "20px 0",
          }}
        />
        <Step
          status={fourStep.status}
          disabled={fourStep.disabled}
          title="Complemento Social"
          style={{
            marginTop: "20px",
          }}
        />
      </Steps>
    </Card>
  );
}
