import React, { useContext } from "react";

import { ThemeContext } from "~/contexts/ThemeContext";

import ProductsServices from "./components/ProductsServices";

import Button from "~/components/button";
import Title from "~/components/typography/title";

import { WalletFormatConversion } from "~/utils/Transformations";

import { Container, ButtonContainer } from "./style";

export default function AgreeWithProductsAndServices({
  donation,
  LoadDonation,
  GoBackOneStep,
  NextStep,
}) {
  const { theme } = useContext(ThemeContext);

  const total = donation.product_services.reduce((value, product) => {
    return value + product.value * product.quantity;
  }, 0);

  return (
    <>
      <Container>
        <ProductsServices
          LoadDonation={LoadDonation}
          theme={theme}
          donationId={donation.id}
          productServices={donation.product_services}
        />

        <Title level={3} textAlign="center">
          {`Preço Total: ${WalletFormatConversion(total)}`}
        </Title>

        <ButtonContainer>
          <Button onClick={GoBackOneStep}>Voltar</Button>
          <Button type="primary" onClick={NextStep}>
            Proximo Passo
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
}
