import React, { useContext } from "react";
// librarys
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  SmileOutlined,
  ShopOutlined,
  RocketOutlined,
  BookOutlined,
} from "@ant-design/icons";

// Own Components
import DonationTypeCard from "./components/Card";
import Card from "~/components/card";
import Divider from "~/components/divider";

import { ThemeContext } from "~/contexts/ThemeContext";
import { ResponsiveContext } from "~/contexts/ResponsiveContext";

// Components
import Title from "~/components/typography/title";
import Button from "~/components/button";

import { Header, ButtonContainer } from "./style";

export default function DonationsType({ kind, setKind, setDonationType }) {
  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);

  return (
    <Card
      bodyStyle={{
        padding: isMobile ? 5 : 24,
      }}
    >
      <Header>
        <Title level={4}>Preciso de ajuda para:</Title>

        <Link style={{ marginTop: isMobile ? 20 : 0 }} to="/doacoes">
          <Button type="primary">Voltar As Doações</Button>
        </Link>
      </Header>

      <Divider borderColor={theme.color.white} />

      <Row>
        <Col xs={12} sm={12} md={8} lg={4} xl={4}>
          <DonationTypeCard
            theme={theme}
            icon={
              <SmileOutlined
                style={{
                  fontSize: "30pt",
                  color:
                    kind === "O Meu Bem Estar"
                      ? theme.color.textOnPrimaryBackground
                      : theme.color.backgroundPrimary,
                }}
              />
            }
            setKind={setKind}
            kind={kind}
            text="O Meu Bem Estar"
          />
        </Col>
        <Col xs={12} sm={12} md={8} lg={4} xl={4}>
          <DonationTypeCard
            icon={
              <HomeOutlined
                style={{
                  fontSize: "30pt",
                  color:
                    kind === "A Minha Moradia"
                      ? theme.color.textOnPrimaryBackground
                      : theme.color.backgroundPrimary,
                }}
              />
            }
            theme={theme}
            setKind={setKind}
            text="A Minha Moradia"
            kind={kind}
          />
        </Col>
        <Col xs={12} sm={12} md={8} lg={4} xl={4}>
          <DonationTypeCard
            theme={theme}
            icon={
              <BookOutlined
                style={{
                  fontSize: "30pt",
                  color:
                    kind === "Os Meus Estudos"
                      ? theme.color.textOnPrimaryBackground
                      : theme.color.backgroundPrimary,
                }}
              />
            }
            setKind={setKind}
            kind={kind}
            text="Os Meus Estudos"
          />
        </Col>
        <Col xs={12} sm={12} md={8} lg={4} xl={4}>
          <DonationTypeCard
            theme={theme}
            kind={kind}
            setKind={setKind}
            icon={
              <RocketOutlined
                style={{
                  fontSize: "30pt",
                  color:
                    kind === "Começar Meu Negócio"
                      ? theme.color.textOnPrimaryBackground
                      : theme.color.backgroundPrimary,
                }}
              />
            }
            text="Começar Meu Negócio"
          />
        </Col>
        <Col xs={12} sm={12} md={8} lg={4} xl={4}>
          <DonationTypeCard
            theme={theme}
            kind={kind}
            setKind={setKind}
            icon={
              <ShopOutlined
                style={{
                  fontSize: "30pt",
                  color:
                    kind === "Ampliar Meu Negócio"
                      ? theme.color.textOnPrimaryBackground
                      : theme.color.backgroundPrimary,
                }}
              />
            }
            text="Ampliar Meu Negócio"
          />
        </Col>
      </Row>

      <ButtonContainer>
        <Button onClick={setDonationType} disabled={kind === ""} type="primary">
          Próxima Etapa
        </Button>
      </ButtonContainer>
    </Card>
  );
}
