import React, { useState, useEffect } from "react";

import { Form, message } from "antd";
import SocialMeAPI from "~/utils/SocialMeAPI";

import Button from "~/components/button";
import Loading from "~/components/loading";
import FormItem from "~/components/form/FormItem";
import { InputDynamicComplete } from "~/components/autoComplete";
import InputNumber from "~/components/inputNumber";
import Input from "~/components/input";
import Select from "~/components/select";

import ProductItem from "./components/ProductItem";

import { Grid } from "./style";

function transform(options, label, value) {
  return options.map((option) => {
    return {
      label: option[label],
      value: option[value].toString(),
    };
  });
}

export default function Products({ propertys }) {
  const [products, setProducts] = useState([]);
  const [unitysMeasure, setUnitysMeasure] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedProduct, setSelectedProduct] = useState({});

  const [form] = Form.useForm();

  const isDebug = propertys.isDebug;

  let timer = null;

  useEffect(() => {
    async function GetUnitsMeasureFromAPI() {
      const params = {
        operation: ["ProductService", "UnitsOfMeasure", "Get"],
      };

      if (isDebug) console.log("📦 Params: ", params);

      const response = await SocialMeAPI(params);

      if (isDebug)
        console.log(
          "🚀 API Response, ProductService UnitsOfMeasure Get: ",
          response
        );

      if (response.success) {
        if (isDebug) console.log("Unist measure was listed successfully");
        const data = transform(response.body.mUnities, "name", "name");
        setUnitysMeasure(data);
        setLoading(false);
      } else if (isDebug) {
        console.log("No unist measure was found");
      }
    }
    GetUnitsMeasureFromAPI();
  }, [isDebug]);

  const SearchOptions = (event) => {
    const { value } = event.target;

    async function GetProductsFromAPI(searchString) {
      const params = {
        operation: ["ProductService", "Search"],
        data: {
          pagination: {
            limit: 1000,
          },
          filter: {
            searchString,
            kind: "PRODUCT",
          },
        },
      };

      if (isDebug) console.log("📦 Params: ", params);

      const response = await SocialMeAPI(params);

      if (isDebug) console.log("🚀 API Response, Product Search: ", response);

      if (response.success) {
        if (isDebug) console.log("Products was listed successfully");
        setProducts(response.body.psModels.elements);
      } else if (isDebug) {
        console.log("No products was found");
      }
    }

    clearTimeout(timer);

    timer = setTimeout(() => {
      GetProductsFromAPI(value);
    }, 500);
  };

  function setProductName(product) {
    setSelectedProduct(product);

    const { name } = product;

    form.setFieldsValue({
      name,
    });

    setProducts([]);
  }

  async function AddProduct(values) {
    const { donation, setDonation } = propertys;

    const {
      name,
      personalized_name,
      description,
      valuePerUnit,
      quantity,
    } = values;

    const { id, avatar, measurement_unity_id } = selectedProduct;

    const data = {
      name: personalized_name,
      description,
      value: Number(valuePerUnit * 1000000),
      product_service_model: id,
      quantity,
      measurement_unity: measurement_unity_id,
    };

    const params = {
      operation: ["Donation", "ProductServices", "Add"],
      urlParams: {
        donationId: donation.id,
      },
      data,
    };

    if (isDebug) console.log("📦 Params: ", params);

    const response = await SocialMeAPI(params);

    if (isDebug)
      console.log("🚀 API Response, Donation ProductServices Add: ", response);

    if (response.success) {
      if (isDebug) console.log("Products was add successfully");

      setDonation({
        ...donation,
        product_services: [
          ...donation.product_services,
          {
            id: response.body.newProductServiceOfDonationRequest.id,
            ps_avatar: avatar,
            ...data,
            ps_name: name,
            ps_kind: "PRODUCT",
          },
        ],
      });

      form.resetFields();

      message.success(`Produto adicionado`);
    } else if (!response.success) {
      if (isDebug) console.log("No product was added");
      message.error("Deve adicionar um dos produtos disponíveis");
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={AddProduct}
          initialValues={{
            quantity: 1,
            valuePerUnit: 1,
            personalized_name: "",
            unitysMeasure: unitysMeasure[unitysMeasure.length - 3].value,
          }}
          style={{
            padding: "10px",
          }}
        >
          <FormItem
            label="Produto"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor, informe um Produto",
              },
            ]}
            item={
              <InputDynamicComplete
                options={products}
                OptionComponent={ProductItem}
                onSelect={setProductName}
                onChange={SearchOptions}
              />
            }
          />

          <FormItem
            label="Nome personalizado"
            name="personalized_name"
            rules={[
              {
                required: true,
                message:
                  "Por favor, informe um nome para identificar seu produto",
              },
            ]}
            item={<Input />}
          />

          <FormItem
            label="Descrição"
            name="description"
            rules={[
              {
                required: true,
                message: "Por favor, informe uma descrição",
              },
            ]}
            item={<Input />}
          />

          <FormItem
            label="Unidade"
            name="unitysMeasure"
            item={<Select options={unitysMeasure} />}
          />

          <Grid>
            <FormItem
              label="Quantidade"
              name="quantity"
              rules={[
                {
                  required: true,
                  message: "Por favor, informe a quantidade",
                },
              ]}
              item={<InputNumber defaultValue={1} min={1} max={999999} />}
            />

            <FormItem
              label="Valor"
              name="valuePerUnit"
              rules={[
                {
                  required: true,
                  message:
                    "Por favor, informe o valor por unidade do seu produto",
                },
              ]}
              item={<InputNumber defaultValue={1} min={1} max={999999} />}
            />
          </Grid>

          <FormItem
            style={{
              marginTop: "30px",
            }}
            item={
              <Button htmlType="submit" type="primary">
                Adicionar Produto
              </Button>
            }
          />
        </Form>
      )}
    </>
  );
}
