import styled from "styled-components";

import System from "~/environments/System";

import { Avatar } from "antd";

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const SubImagesContent = styled.div`
  display: flex;
  margin: 20px 0;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    align-items: space-between;
    justify-content: space-between;
  }
`;

export const Image = styled(Avatar)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  & + & {
    margin-left: 10px;
  }

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    & + & {
      margin-left: 0;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
