import React, { useContext } from "react";

import { ThemeContext } from "~/contexts/ThemeContext";
import { ResponsiveContext } from "~/contexts/ResponsiveContext";

import Text from "~/components/typography/text";
import Badge from "~/components/badge";

import {
  WalletFormatConversion,
  CapitalizeString,
} from "~/utils/Transformations";

// import SocialMeAPI from '~/utils/SocialMeAPI';

import { ContainerList, List, ListItem, PrimarySecondaryText } from "./style";

export default function ProductServices({ donation }) {
  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);

  return (
    <ContainerList>
      {donation.product_services.map((item) => (
        <List key={item.id} borderColor={theme.color.borderColor}>
          <ListItem position="flex-start" width="100%">
            <PrimarySecondaryText>
              <Text strong style={{ fontSize: 14 }}>
                {CapitalizeString(item.name)}
              </Text>
              <Text style={{ fontSize: 12 }} kind="secondary">
                {CapitalizeString(item.description)}
              </Text>
            </PrimarySecondaryText>
          </ListItem>
          <ListItem position="flex-start" width="45%">
            <Text
              style={{ fontSize: 14 }}
              strong
            >{`Valor R$${WalletFormatConversion(
              item.quantity * item.value
            )}`}</Text>
          </ListItem>
          <ListItem position={isMobile ? "flex-end" : "center"} width="45%">
            <Badge
              status={item.already_donated ? "success" : "error"}
              text={item.already_donated ? "Doado" : "Pendente"}
            />
          </ListItem>
        </List>
      ))}
    </ContainerList>
  );
}
