export default {
  SM39B2F64: {
    message: 'Browser Successfully Invalidated',
  },
  SM1DE89BB: {
    message: 'The parameter QRCode that was sent is invalid',
  },
  SM29A62AE: {
    message: "The parameter 'role' is required but wasn't sent",
  },
};
