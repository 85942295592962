import React from "react";
import Card from "~/components/card";
import Text from "~/components/typography/text";

import { Container, FlexCenter, Div, Padding } from "./style";

export default function DonationsTypeCard({
  icon,
  text,
  theme,
  kind,
  setKind,
}) {
  const active = text === kind;

  return (
    <Padding onClick={() => setKind(text)}>
      <Card bodyStyle={{ padding: 0 }}>
        <Container
          backgroundColor={
            active
              ? theme.color.backgroundPrimary
              : theme.color.textOnPrimaryBackground
          }
          borderColor={theme.color.backgroundPrimary}
        >
          <FlexCenter>{icon}</FlexCenter>
          <Div>
            <Text
              style={{ textAlign: "center" }}
              color={
                active
                  ? theme.color.textOnPrimaryBackground
                  : theme.color.backgroundPrimary
              }
              textAlign="center"
            >
              {text}
            </Text>
          </Div>
        </Container>
      </Card>
    </Padding>
  );
}
