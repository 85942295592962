import React, { useContext, useState } from "react";

import { message, Modal } from "antd";
import { useHistory } from "react-router-dom";

// Components
import SocialMeAPI from "~/utils/SocialMeAPI";

import { ThemeContext } from "~/contexts/ThemeContext";

import Button from "~/components/button";
import Text from "~/components/typography/text";
import Title from "~/components/typography/title";

import { Margin, ButtonContainer } from "./style";

import AgreedTermsModal from "./components/AgreedTermsModal";

export default function Terms({ donationId, GoBack, isDebug }) {
  const history = useHistory();

  const { theme } = useContext(ThemeContext);

  const [modalVisibility, setModalVisibility] = useState(false);

  async function AgreedWithTerms() {
    const params = {
      operation: ["Donation", "Submit"],
      urlParams: {
        donationId,
      },
    };

    if (isDebug) console.log("📦 Params: ", params);

    const response = await SocialMeAPI(params);

    if (isDebug) console.log("🚀 API Response, Donation Submit: ", response);

    if (response.success) {
      if (isDebug) console.log("The donation was submited to a administer");

      message.success("Pedido de doação solicitado com sucesso.");

      history.push("/doacoes");
    } else if (!response.sucess) {
      if (isDebug) console.log("The donation was not submited to a administer");

      message.error("Há campos obrigatórios mas não preenchidos.");
    }
  }

  function Agreed() {
    AgreedWithTerms();
    setModalVisibility(false);
  }

  return (
    <>
      <Modal
        style={{ padding: 10 }}
        title="Detalhes da sua doação"
        cancelText=""
        visible={modalVisibility}
        onCancel={() => setModalVisibility(false)}
        footer={[
          <Button
            style={{ marginRight: "auto" }}
            onClick={() => setModalVisibility(false)}
          >
            Cancelar
          </Button>,
          <Button onClick={Agreed} type="primary">
            Solicitar doação
          </Button>,
        ]}
      >
        <AgreedTermsModal theme={theme} />
      </Modal>
      <Margin>
        <Title textAlign="center" level={3}>
          Termos
        </Title>
      </Margin>
      <Text
        color={theme.color.textOnSecondaryBackground}
        style={{ fontSize: 16, fontWeight: "400" }}
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet harum
        fugiat maxime tenetur aspernatur minus doloribus nesciunt illo, dolor
        provident obcaecati libero. Illo, sunt sapiente unde, maxime magnam
        quidem deleniti ad dolor asperiores maiores officiis incidunt dolorem
        expedita fuga. Illo veritatis culpa est voluptate dolorum accusamus
        ducimus officia quas dignissimos libero ex perspiciatis neque maiores
        similique molestiae explicabo, doloremque in perferendis assumenda quis
        architecto. Nisi dignissimos omnis excepturi facilis et maiores.
        Pariatur voluptatem molestias consequatur sapiente ab eaque ipsum,
        facilis tempora dicta officia illo voluptas non maxime ea, incidunt
        explicabo repellendus animi amet. Dolorem distinctio cum, reiciendis
        quae obcaecati ipsum.
      </Text>
      <ButtonContainer>
        <Button onClick={GoBack}>Voltar</Button>
        <Button onClick={() => setModalVisibility(true)} type="primary">
          Aceito os Termos
        </Button>
      </ButtonContainer>
    </>
  );
}
