import styled from "styled-components";

import {
  SyncOutlined,
  CheckCircleOutlined,
  IssuesCloseOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import System from "~/environments/System";

export const IssuesIcon = styled(IssuesCloseOutlined)`
  color: ${(props) => props.color};
  margin-right: 10px;
`;

export const SyncIcon = styled(SyncOutlined)`
  color: ${(props) => props.color};
  margin-right: 10px;
`;

export const CloseIcon = styled(CloseCircleOutlined)`
  color: ${(props) => props.color};
  margin-right: 10px;
`;

export const CheckIcon = styled(CheckCircleOutlined)`
  color: ${(props) => props.color};
  margin-right: 10px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  margin-bottom: 15px;

  @media (max-width: ${`${System.TabletBreakpoint}px`}) {
    align-items: flex-start;
    justify-content: space-around;
    padding: 0 8%;
  }

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const FilterCard = styled.div`
  position: relative;
  padding: 5px 12px;
  border-radius: 8px;
  border: 2px solid ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  margin-right: 10px;
  transition: 0.5s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: ${`${System.TabletBreakpoint}px`}) {
    margin-bottom: 10px;
    padding: 5px;
  }

  & span,
  & strong {
    color: ${(props) => props.textColor};
  }
  &:hover {
    background-color: ${(props) => {
      return props.active ? props.borderColor : props.textColor;
    }};

    & span,
    & strong {
      color: ${(props) => {
        return props.active ? props.textColor : props.backgroundColor;
      }};
    }
  }
`;
