import styled from "styled-components";
import { Card } from "antd";

export const MainCard = styled(Card)`
  width: ${(style) => style.width};
  height: ${(style) => style.height};
  margin: ${(style) => style.margin};
  margin-top: ${(style) => style.marginTop};
  margin-right: ${(style) => style.marginRight};
  margin-bottom: ${(style) => style.marginBottom};
  margin-left: ${(style) => style.marginLeft};
  background-color: ${(style) => style.backgroundcolor || "#fff"};
  color: ${(style) => style.color};
  border: ${(style) => style.border};
  border-radius: 8px;
`;
