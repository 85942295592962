import API from '~/environments/APIs';

const Donation = {
  GetAll: {
    baseURL: API.socialMe,
    url: '/donation/requests',
    method: 'post',
    timeout: '50000',
  },
  GetMy: {
    baseURL: API.socialMe,
    url: '/donation/requests',
    method: 'post',
    timeout: '50000',
  },
  GetPending: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/donationRequests',
    method: 'post',
    timeout: '50000',
  },
  Show: {
    baseURL: API.socialMe,
    url: '/donation/request/donationId',
    method: 'get',
    timeout: '50000',
  },
  Add: {
    baseURL: API.socialMe,
    url: '/donation/request',
    method: 'post',
    timeout: '50000',
  },
  Edit: {
    baseURL: API.socialMe,
    url: '/donation/request/donationId',
    method: 'put',
    timeout: '50000',
  },
  File: {
    baseURL: API.socialMe,
    url: '/donation/request/donationId/file',
    method: 'post',
    timeout: '50000',
  },
  Submit: {
    baseURL: API.socialMe,
    url: '/donation/request/donationId/complete',
    method: 'post',
    timeout: '50000',
  },
  ProductServices: {
    GetAll: {
      baseURL: API.socialMe,
      url: '/donation/request/donationId/product-services',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/donation/request/donationId/product-service',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/donation/request/donationId/product-service/productServiceId',
      method: 'put',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/donation/request/donationId/product-service/productServiceId',
      method: 'delete',
      timeout: '50000',
    },
  },
};

export default Donation;
